/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable radix */
import React, { useState } from 'react';
import {
    Grid,
    Typography,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    Card,
    TableBody,
    Link,
    IconButton,
    CircularProgress
} from '@mui/material';
import { AccessTime, CheckCircle, Print } from '@mui/icons-material';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import useTournaments from 'hooks/useTournaments';

const MatchesTable = ({ matches, handleDrawerOpen, setSelectedMatchId, isAdmin }) => {
    const { printMatches, loading } = useTournaments();
    const [printMatchId, setPrintMatchId] = useState(null); // State to track the match being printed

    const handlePrintClick = async (matchId) => {
        setPrintMatchId(matchId);
        await printMatches({ match_id: matchId });
        setPrintMatchId(null); // Reset the state after printing
    };


    return (
        <Grid data-test-id="views/admin/AdminHub/TournamentManager/MatchesTable">
            <Card>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell> Match ID</TableCell>
                                <TableCell> Court</TableCell>
                                <TableCell> DUPR </TableCell>
                                <TableCell align="center">Team</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell sx={{ marginLeft: 2 }} align="center">
                                    Team
                                </TableCell>
                                {isAdmin && <TableCell>Print</TableCell>}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {matches?.map((match) => (
                                <TableRow key={match.id}>
                                    <TableCell>
                                        <Link
                                            component="button"
                                            disabled={!isAdmin}
                                            onClick={() => {
                                                setSelectedMatchId(match.id);
                                                handleDrawerOpen();
                                            }}
                                        >
                                            {match.id}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        <Typography>{match.court?.name}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {match.winner_id ? (
                                            match.dupr_match_code ? (
                                                <CheckCircle color="success" />
                                            ) : (
                                                <AccessTime color="warning" />
                                            )
                                        ) : null}
                                    </TableCell>
                                    <TableCell>
                                        <CondensedTeamCard match={match} party={match.team_a} />
                                    </TableCell>
                                    <TableCell>
                                        {match?.match_games?.map((item, index) => {
                                            const wonGame =
                                                parseInt(item.game_scores.find((score) => score.team_id === match?.team_a?.id)?.score) >
                                                parseInt(
                                                    match?.match_games[index]?.game_scores.find((score) => score.team_id === match.team_b.id)
                                                        ?.score
                                                );
                                            const gameScores = item.game_scores.find((score) => score.team_id === match.team_a.id)?.score;
                                            if (gameScores !== undefined) {
                                                return (
                                                    <Grid
                                                        mt={0.5}
                                                        sx={{
                                                            backgroundColor: wonGame ? 'green' : 'red',
                                                            textAlign: 'center',
                                                            paddingX: 1,
                                                            paddingY: 0.25
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="white">
                                                            {gameScores}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        })}
                                    </TableCell>
                                    <TableCell>VS</TableCell>
                                    <TableCell>
                                        {match?.match_games?.map((item, index) => {
                                            const wonGame =
                                                parseInt(item.game_scores?.find((score) => score.team_id === match.team_b.id)?.score) >
                                                parseInt(
                                                    match.match_games[index]?.game_scores?.find((score) => score.team_id === match.team_a.id)
                                                        ?.score
                                                );
                                            const gameScores = item.game_scores.find((score) => score.team_id === match.team_b.id)?.score;
                                            if (gameScores !== undefined) {
                                                return (
                                                    <Grid
                                                        mt={0.5}
                                                        sx={{
                                                            backgroundColor: wonGame ? 'green' : 'red',
                                                            textAlign: 'center',
                                                            paddingX: 1,
                                                            paddingY: 0.25
                                                        }}
                                                    >
                                                        <Typography variant="h5" color="white">
                                                            {gameScores}
                                                        </Typography>
                                                    </Grid>
                                                );
                                            }
                                            return null;
                                        })}
                                    </TableCell>
                                    <TableCell>
                                        <CondensedTeamCard match={match} party={match.team_b} />
                                    </TableCell>
                                    {isAdmin && (
                                        <TableCell>
                                            <IconButton
                                                onClick={() => handlePrintClick(match.id)}
                                                disabled={loading?.printMatches && printMatchId === match.id}
                                            >
                                                {loading?.printMatches && printMatchId === match.id ? (
                                                    <CircularProgress size={24} />
                                                ) : (
                                                    <Print color={match?.printed ? 'success' : 'grey'} />
                                                )}
                                            </IconButton>
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid>
    );
};

export default MatchesTable;
