import { Box, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/styles';

const SaveFooter = ({ handleCancel, handleSave = null }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                paddingY: 2,
                paddingX: 2,
                backgroundColor: 'white',
                zIndex: 1, // Adjust if needed to ensure it's above other elements
                boxShadow: '0 -2px 4px rgba(0,0,0,0.1)' // Optional shadow for better separation
                // padding: theme.spacing(1) // Adjust padding as needed
            }}
        >
            <Grid container justifyContent="center">
                <Grid item>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancel}
                        sx={{ mr: theme.spacing(3), paddingX: 6, paddingY: 1 }}
                    >
                        Cancel
                    </Button>
                </Grid>

                <Grid item>
                    {!handleSave ? (
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit" // This will trigger form submission
                            sx={{ mr: theme.spacing(3), paddingX: 6, paddingY: 1 }}
                        >
                            Save
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleSave()}
                            sx={{ mr: theme.spacing(3), paddingX: 6, paddingY: 1 }}
                        >
                            Save
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};
export default SaveFooter;
