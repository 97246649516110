/* eslint-disable prettier/prettier */
/* eslint-disable react/no-this-in-sfc */
import { Button, FormHelperText, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import CardModal from 'ui-component/CardModal';
import FormComponents from 'ui-component/FormComponents';
import * as Yup from 'yup';
import moment from 'moment';
import PlayerCell from 'views/components/playerCell.component';
import { current } from '@reduxjs/toolkit';
import CurrencyFormat from 'react-currency-format';
import useClubs from 'hooks/useClubs';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import actionSnackbar from 'ui-component/actionSnackbar';
import useEvents from 'hooks/useEvents';
import FieldSection from 'ui-component/FieldSection';
import { EmojiEvents } from '@mui/icons-material';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CourtResoModal = ({ open, toggleModal, selectedRange, selectedResource, groupId, mappedEvents, isAdmin = false, eventId }) => {
    const { user } = useAuth();
    const { intentToken, newPaymentIntent, updatePaymentIntent, setValue, getClubCourtPrice } = useClubs();
    const { reserveCourt } = useEvents();
    const [currentTab, setCurrentTab] = useState(0);
    const [hourlyRate, setHourlyRate] = useState(undefined)
    const [price, setPrice] = useState();
    const [subtotal, setSubtotal] = useState();
    const [taxes, setTaxes] = useState();
    const [maxLeadTimeError, setMaxLeadTimeError] = useState('');




    const initialValues = {
        date: selectedRange.start,
        start: selectedRange.start,
        end: selectedRange.end,
        first_name: user?.name || '',
        title: isAdmin ? 'Blocked Time' : undefined,
        last_name: user?.name || '',
        email: user?.email || '',
        phone_number: user?.user_attributes?.phone_number || '',
        user_id: user?.id || undefined,
        group_id: groupId
    };

    const validationSchema = Yup.object({
        start: Yup.date().required('Start time is required'),
        end: Yup.date()
            .required('End time is required')
            .test('is-greater', 'End time must be later than start time', function (value) {
                const { start } = this.parent;
                return moment(value).isAfter(moment(start));
            })
            .test('min-duration', `The duration must be at least ${selectedResource.minDuration} minutes`, function (value) {
                if (isAdmin) {
                    return true;
                }
                const { start } = this.parent;
                const duration = moment(value).diff(moment(start), 'minutes');
                return duration >= selectedResource.minDuration;
            })
            .test('max-duration', `The duration must not exceed ${selectedResource.maxDuration} minutes`, function (value) {
                if (isAdmin) {
                    return true;
                }
                const { start } = this.parent;
                const duration = moment(value).diff(moment(start), 'minutes');
                return duration <= selectedResource.maxDuration;
            })
            .test('no-overlap', 'The selected time overlaps with an existing booking for this court', function (value) {
                if (isAdmin) {
                    return true;
                }
                const { start } = this.parent;
                const newStart = moment(start);
                const newEnd = moment(value);
                const selectedResourceId = parseInt(selectedResource.id, 10); // Ensure selectedResourceId is an integer

                return !mappedEvents.some((event) => {
                    if (event.resourceIds.includes(selectedResourceId)) {
                        const eventStart = moment(event.start);
                        const eventEnd = moment(event.end);

                        const isOverlapping =
                            (newStart.isSameOrAfter(eventStart) && newStart.isBefore(eventEnd)) ||
                            (newEnd.isAfter(eventStart) && newEnd.isSameOrBefore(eventEnd)) ||
                            (newStart.isSameOrBefore(eventStart) && newEnd.isSameOrAfter(eventEnd));

                        return isOverlapping;
                    }
                    return false;
                });
            })
            .test('within-business-hours', 'The selected time is outside the business hours', function (value) {
                if (isAdmin) {
                    return true;
                }
                const { start } = this.parent;
                const startTime = moment(start);
                const endTime = moment(value);
                const dayOfWeek = startTime.day(); // Get the day of the week as a number (0 for Sunday, 1 for Monday, etc.)

                const businessHour = selectedResource.businessHours.find((bh) => bh.daysOfWeek.includes(dayOfWeek));

                if (!businessHour) {
                    // If there are no business hours defined for the day, consider it closed
                    return false;
                }

                const openTime = moment(`${startTime.format('YYYY-MM-DD')} ${businessHour.startTime}`);
                const closeTime = moment(`${startTime.format('YYYY-MM-DD')} ${businessHour.endTime}`);

                const isWithinBusinessHours = startTime.isSameOrAfter(openTime) && endTime.isSameOrBefore(closeTime);

                return isWithinBusinessHours;
            }),
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),

    });
    console.log('subtotal', subtotal)
    const formik = useFormik({
        initialValues,
        validationSchema,

        onSubmit: (values, { resetForm }) => {
            if (isAdmin || subtotal === 0) {
                reserveCourt(values, subtotal, selectedResource.id, true, null, eventId).then(() => {
                    toggleModal();
                    actionSnackbar(true, eventId ? 'Reservation Updated' : 'Court Reserved');
                });
            } else if (currentTab === 0) {
                if (intentToken) {
                    updatePaymentIntent(intentToken.id, subtotal / 100).then(() => {
                        setCurrentTab(1);
                    });
                } else {
                    newPaymentIntent(subtotal / 100, formik.values.email).then(() => {
                        setCurrentTab(1);
                    });
                }
            }
        }
    });

    useEffect(() => {
        if (open) {
            getClubCourtPrice(selectedResource?.id, selectedRange?.start).then((res) => {
                setHourlyRate(res)
            })
        }
    }, [open, formik.values.start])
    useEffect(() => {
        const validateMaxLeadTime = () => {
            const now = moment();
            const startDatetime = moment(
                `${moment(formik.values.date).format('YYYY-MM-DD')} ${moment(formik.values.start).format('HH:mm')}`
            );
            const maxDate = now.add(selectedResource.maxLeadTime * 24, 'hours');
            if (!startDatetime.isSameOrBefore(maxDate)) {
                setMaxLeadTimeError(`Reservations cannot be made more than ${selectedResource.maxLeadTime} days in advance.`);
            } else {
                setMaxLeadTimeError('');
            }
        };
        if (!isAdmin) {
            validateMaxLeadTime();
        }
    }, [formik.values.date, formik.values.start, selectedResource.maxLeadTime]);
    const getActionText = (tab) => {
        switch (tab) {
            case 0:
                if (isAdmin || eventId) {
                    return 'Save';
                } if (subtotal === 0) {
                    return 'Reserve'
                }
                return 'Continue';
            default:
                return 'Continue';
        }
    };

    useEffect(() => {
        console.log('hourlyRate', hourlyRate)
        if (hourlyRate > 0) {
            const start = moment(formik.values.start);
            const end = moment(formik.values.end);
            const duration = end.diff(start, 'minutes');
            if (duration > 0 && hourlyRate > 0) {
                const calculatedPrice = (duration / 60) * hourlyRate;
                setPrice(calculatedPrice);
                // const taxCalc = calculatedPrice * 0.0625;
                // setTaxes(taxCalc);
                setSubtotal(calculatedPrice);
            } else {
                setPrice(0)
                setSubtotal(0)
            }
        } else {
            setSubtotal(0)
        }
    }, [formik.values.start, formik.values.end, selectedResource.id, hourlyRate]);

    useEffect(() => {
        if (currentTab === 1) {
            if (intentToken) {
                updatePaymentIntent(intentToken.id, subtotal / 100);
            } else {
                newPaymentIntent(subtotal / 100, formik.values.email);
            }
        }
    }, [subtotal]);

    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const [paymentError, setPaymentError] = useState('');
        const [processing, setProcessing] = useState(false);

        const handlePaymentError = (error) => {
            if (error.type === 'card_error' || error.type === 'validation_error') {
                setPaymentError(error.message);
            } else {
                setPaymentError('An unexpected error occurred.');
            }
            actionSnackbar(false, 'Checkout Error');
        };
        const handleSubmit = async (event) => {
            event.preventDefault();
            setProcessing(true);

            if (!stripe || !elements) {
                return;
            }

            try {
                const paymentResult = await stripe.confirmPayment({
                    elements,
                    redirect: 'if_required',
                    confirmParams: {
                        // Make sure to change this to your payment completion page
                        // return_url: `${window.location.origin}/completion`
                    }
                });

                if (paymentResult.error) {
                    // Handle errors returned by Stripe
                    handlePaymentError(paymentResult.error);
                    setProcessing(false);
                } else {
                    reserveCourt(formik.values, subtotal, selectedResource.id, false, paymentResult, null)
                        .then(() => {
                            setProcessing(false);
                            toggleModal();
                            setValue('intentToken', null);
                            // Proceed with checkout completion
                            setPaymentError('');
                            actionSnackbar(true, 'Court Reserved');
                        })
                        .catch(() => {
                            actionSnackbar(false, 'Uh oh, someone beat you to it');
                        });
                }
            } catch (error) {
                // Handle other errors (e.g., network errors)
                handlePaymentError(error);
            }
        };

        const options = {
            layout: {
                type: 'tabs',
                defaultCollapsed: false
            }
        };

        return (
            <form onSubmit={handleSubmit}>
                <CardModal
                    open={open}
                    title="New Court Reservation"
                    toggleModal={toggleModal}
                    content={
                        <Grid container>
                            <Grid item xs={12}>
                                <OrderSummary />
                            </Grid>
                            <Grid item xs={12}>
                                <PaymentElement options={options} id="payment-element" />
                            </Grid>
                        </Grid>
                    }
                    actionText="Checkout"
                    showCancel
                    submitAction={handleSubmit}
                    cancelAction={() => setCurrentTab(0)}
                    loading={processing}
                />
            </form>
        );
    };
    const OrderSummary = () => (
        <TableContainer>
            <Typography variant="h4"> Order Summary </Typography>

            <Table sx={{ minWidth: 'auto' }} size="small" aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell />
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            Court Fee <br /> {selectedResource?.name} - ${hourlyRate / 100} / hr
                        </TableCell>
                        <TableCell align="right">
                            <Typography variant="subtitle1">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={(price || 0) / 100}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                />
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant="subtitle1">Total</Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 'none' }}>
                            <Typography variant="subtitle1">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={(subtotal || 0) / 100}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const getContent = (tab) => {
        switch (tab) {
            case 0:
                return (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4"> Booking Info</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FieldSection icon={<EmojiEvents />} label={<Typography variant="h4">{selectedResource?.name}</Typography>} />
                        </Grid>
                        {isAdmin && (
                            <>
                                <FormComponents
                                    input={{
                                        type: 'text',
                                        id: 'title',
                                        name: 'title',
                                        label: 'Title',
                                        value: formik.values.title,
                                        onChange: formik.handleChange,
                                        touched: formik.touched.title,
                                        onBlur: formik.handleBlur,
                                        error: formik.errors.title,
                                        width: 12
                                    }}
                                    formik={formik}
                                />
                            </>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="h4"> Date & Time</Typography>
                        </Grid>
                        <FormComponents
                            input={{
                                type: 'date',
                                id: 'date',
                                name: 'date',
                                label: 'Date',
                                value: formik.values.date,
                                onChange: formik.handleChange,
                                touched: true,
                                onBlur: formik.handleBlur,
                                error: formik.errors.date,
                                minDate: moment(),
                                width: 12
                            }}
                            formik={formik}
                        />

                        <FormComponents
                            input={{
                                type: 'time',
                                id: 'start',
                                name: 'start',
                                label: 'From',
                                value: formik.values.start,
                                onChange: formik.handleChange,
                                touched: formik.touched.start,
                                onBlur: formik.handleBlur,
                                error: formik.errors.start,
                                width: 12,
                                smWidth: 6,
                                minutesStep: 30
                            }}
                            formik={formik}
                        />

                        <FormComponents
                            input={{
                                type: 'time',
                                id: 'end',
                                name: 'end',
                                label: 'To',
                                value: formik.values.end,
                                onChange: formik.handleChange,
                                touched: formik.touched.end,
                                onBlur: formik.handleBlur,
                                error: formik.errors.end,
                                helperText: formik.errors.end,
                                width: 12,
                                smWidth: 6,
                                minutesStep: 30
                            }}
                            formik={formik}
                        />
                        <Grid item xs={12}>
                            <Typography variant="h4"> Booking User</Typography>
                        </Grid>
                        {user ? (
                            <Grid item xs={12}>
                                <PlayerCell user={user} />
                            </Grid>
                        ) : (
                            <>
                                <FormComponents
                                    input={{
                                        type: 'text',
                                        id: 'first_name',
                                        name: 'first_name',
                                        label: 'First Name',
                                        value: formik.values.first_name,
                                        onChange: formik.handleChange,
                                        touched: formik.touched.first_name,
                                        onBlur: formik.handleBlur,
                                        error: formik.errors.first_name,
                                        width: 12,
                                        smWidth: 6
                                    }}
                                    formik={formik}
                                />
                                <FormComponents
                                    input={{
                                        type: 'text',
                                        id: 'last_name',
                                        name: 'last_name',
                                        label: 'Last Name',
                                        value: formik.values.last_name,
                                        onChange: formik.handleChange,
                                        touched: formik.touched.last_name,
                                        onBlur: formik.handleBlur,
                                        error: formik.errors.last_name,
                                        width: 12,
                                        smWidth: 6
                                    }}
                                    formik={formik}
                                />

                                <FormComponents
                                    input={{
                                        type: 'text',
                                        id: 'email',
                                        name: 'email',
                                        label: 'Email',
                                        value: formik.values.email,
                                        onChange: formik.handleChange,
                                        touched: formik.touched.email,
                                        onBlur: formik.handleBlur,
                                        error: formik.errors.email,
                                        width: 12,
                                        smWidth: 6
                                    }}
                                    formik={formik}
                                />

                                <FormComponents
                                    input={{
                                        type: 'phone-number',
                                        id: 'phone_number',
                                        name: 'phone_number',
                                        label: 'Phone Number',
                                        value: formik.values.phone_number,
                                        onChange: formik.handleChange,
                                        touched: formik.touched.phone_number,
                                        onBlur: formik.handleBlur,
                                        error: formik.errors.phone_number,
                                        width: 12,
                                        smWidth: 6
                                    }}
                                    formik={formik}
                                />
                            </>
                        )}

                        {!isAdmin && (
                            <Grid item xs={12}>
                                <OrderSummary />
                            </Grid>
                        )}
                        {maxLeadTimeError && (
                            <Grid item xs={12}>
                                <Typography color="error">{maxLeadTimeError}</Typography>
                            </Grid>
                        )}
                    </Grid>
                );
            case 1:
                return (
                    <Grid>
                        <Elements stripe={stripePromise} options={{ clientSecret: intentToken?.client_secret }}>
                            <CheckoutForm />
                        </Elements>
                    </Grid>
                );
            default:
                return <></>;
        }
    };
    console.log('values', formik.values)
    if (currentTab === 0) {
        return (
            <form onSubmit={formik.handleSubmit}>
                <CardModal
                    open={open}
                    title={!eventId ? 'Create Court Reservation' : 'Edit Reservation'}
                    toggleModal={toggleModal}
                    content={getContent(currentTab)}
                    actionText={getActionText(currentTab)}
                    showCancel
                    submitAction={formik.handleSubmit}
                    buttonDisabled={Object.keys(formik.errors).length > 0 || maxLeadTimeError !== '' || hourlyRate === undefined}
                />
            </form>
        );
    }
    return (
        <Grid>
            <Elements stripe={stripePromise} options={{ clientSecret: intentToken?.client_secret }}>
                <CheckoutForm />
            </Elements>
        </Grid>
    );
};

export default CourtResoModal;
