/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, createContext, useReducer } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { API_URL as apiUrl } from 'config';
import moment from 'moment-timezone';
import useLeagues from 'hooks/useLeagues';

export const TournamentsContext = createContext();
function getNextHour(hours) {
    const now = new Date();
    now.setHours(now.getHours() + hours);
    now.setMinutes(0);
    now.setSeconds(0);
    now.setMilliseconds(0);
    return now;
}
const initialState = {
    tournamentInfo: {
        title: '',
        clubId: '',
        visibility: 'public',
        location: '',
        startDate: getNextHour(1),
        endDate: getNextHour(2),
        timezone: moment.tz.guess()
        // ... other tournament related properties
    },
    isLoading: false,
    tournamentDetails: {},
    dashboard: {},
    error: undefined,
    leagueCourts: [],
    courtQueue: [],
    tournamentAdmins: [],
    courtQueueLastPage: undefined,
    loading: { eventAdmins: true, courtQueue: true, eventTickets: false, printMatches: false, eventDiscounts: false },
    court: {},
    viewType: { courtQueue: 'normal', checkIn: 'condensed' },
    queueFilters: { showBlocked: 1 },
    courtQueueItem: {},
    eventTickets: [],
    eventTicket: {},
    eventDiscounts: [],
    errors: {}
    // ... other state properties if needed
};
const UPDATE_NESTED_STATE_KEY = 'UPDATE_NESTED_STATE_KEY';

const updateNestedStateKey = (keyPath, value) => ({
    type: UPDATE_NESTED_STATE_KEY,
    payload: { keyPath, value }
});
function updateNestedStateKeyReducer(state, keyPath, value) {
    const keys = keyPath.split('.');
    const nestedState = { ...state };

    let currentLevel = nestedState;
    for (let i = 0; i < keys.length - 1; i++) {
        const key = keys[i];
        currentLevel[key] = { ...currentLevel[key] };
        currentLevel = currentLevel[key];
    }

    currentLevel[keys[keys.length - 1]] = value;

    return nestedState;
}

function tournamentsReducer(state, action) {
    switch (action.type) {
        case 'SET_TOURNAMENT_INFO':
            return {
                ...state,
                tournamentInfo: {
                    ...state.tournamentInfo,
                    ...action.payload
                }
            };
        case 'RESET_STATE':
            return initialState;
        // ... other action cases
        case 'SET_STATE':
            return { ...state, [action.payload.key]: action.payload.value };
        case UPDATE_NESTED_STATE_KEY:
            return updateNestedStateKeyReducer(state, action.payload.keyPath, action.payload.value);
        default:
            return state;
    }
}

export function TournamentsProvider({ children }) {
    const [state, dispatch] = useReducer(tournamentsReducer, initialState);
    const { setValue: setLeagueValue, setEventPools, setMatches, eventTeams, replaceArrayItem } = useLeagues();
    const [selectedEvents, setSelectedEvents] = useState({});

    const setValue = (key, value) => {
        dispatch({ type: 'SET_STATE', payload: { key, value } });
    };
    const updateValue = (keyPath, value) => {
        dispatch(updateNestedStateKey(keyPath, value));
    };

    const setTournamentValue = (key, value) => {
        dispatch({
            type: 'SET_TOURNAMENT_INFO',
            payload: { [key]: value }
        });
    };

    const resetState = () => {
        updateValue('errors', {});
        dispatch({ type: 'RESET_STATE' });
    };

    // eslint-disable-next-line default-param-last
    const createTournament = async (id = null, params) => {
        setValue('isLoading', true);

        const response = await axios
            .post(`/api/tournament`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                return res.data.data;
            })
            .catch((err) => {
                console.log('ERR4', err);
                setValue('isLoading', false);
            });
        return response;
    };
    const mergeDivisions = async (division_id, new_division_id) => {
        const params = { division_id, new_division_id };
        const response = await axios
            .post(`/api/divisions/merge`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => res.data)
            .catch((err) => {
                console.log('ERR4', err);
                throw err;
            });
        return response;
    };
    const getTournamentHub = async (id) => {
        const response = await axios
            .get(`/api/tournament/hub/${id}`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('tournamentDetails', res.data.tournament);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.tournament;
            })
            .catch((err) => {
                setValue('isLoading', false);
                setValue('error', err);
            });
        return response;
    };
    const getLeagueHub = async (id) => {
        const response = await axios
            .get(`/api/league/hub/${id}`)
            .then((res) => {
                setValue('isLoading', false);
                setValue('tournamentDetails', res.data.tournament);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.tournament;
            })
            .catch((err) => {
                console.log('err', err);
                setValue('isLoading', false);
                setValue('error', err);
            });
        return response;
    };
    const getEventHubDashboard = async (id, type) => {
        const response = await axios
            .get(`/api/event/hub/dashboard/${id}`, { params: { type } })
            .then((res) => {
                setValue('isLoading', false);
                setValue('dashboard', res.data.dashboard);
                // setLeagueLeaderboard(res.data.leaderboard);

                return res.data.tournament;
            })
            .catch((err) => {
                console.log('err', err);
                setValue('isLoading', false);
                setValue('error', err);
            });
        return response;
    };
    const editTournament = async (id, params) => {
        setValue('isLoading', true);

        const response = await axios
            .put(`/api/tournament/${id}`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                setValue('tournamentDetails', res.data.data);

                // getTournamentHub(id);
                return res.data.data;
            })
            .catch((err) => {
                console.log('ERR4', err);
                setValue('isLoading', false);
                throw err;
            });
        return response;
    };
    const editLeague = async (id, params, images) => {
        const formData = new FormData();

        if (params.waiver) {
            formData.append('waiver', params.waiver);
            formData.append('waiver_name', params.waiver.name);
        }
        const latitude = params?.location?.geometry?.location.lat();
        const longitude = params?.location?.geometry?.location.lng();
        if (!params.max_participants_bool) {
            params.max_participants = null;
        }
        if (!params.minimum_rating_bool === false && !params.editStatus) {
            params.minimum_rating = null;
        }
        if (params.entry_fee_bool === false && !params.editStatus) {
            params.entry_fee = 0;
        }
        let updatedParams = { ...params };

        if (params.location?.formatted_address) {
            updatedParams = {
                ...params,
                // start_time: startTime.format('YYYY-MM-DDTHH:mm:ss'),
                // end_time: endTime.format('YYYY-MM-DDTHH:mm:ss'),
                street_address: params.location ? params.location?.formatted_address : null,
                city: params.location ? params.location?.address_components[2].long_name : null,
                country: params.location ? params.location?.address_components[5].long_name : null,
                zip: params.location ? params.location?.address_components[6].long_name : null,
                state: params.location ? params.location?.address_components[4].long_name : null,
                venue: params.location ? params.location?.name : null,
                latitude,
                longitude
            };
        }
        console.log('params', params);

        // eslint-disable-next-line array-callback-return
        if (images) {
            // formData.append('image_array', images);
            // console.log('images', images);
            formData.append('images_modified', true);
            for (const image of images) {
                if (image.file) {
                    formData.append('images[]', image.file, image.id || `${Date.now()}.jpg`);
                    formData.append('image_ids[]', 'new_image');
                } else {
                    formData.append('images[]', null);

                    formData.append('image_ids[]', image.id);
                }
            }
            // formData.append('images_modified', false);
        }

        Object.keys(updatedParams).forEach((key) => {
            const value = updatedParams[key];
            if (value === true) {
                formData.append(key, 1);
            } else if (value === false) {
                formData.append(key, 0);
            } else if (value === null) {
                formData.append(key, ''); // Or handle it as you need
            } else if (value !== undefined) {
                formData.append(key, value);
            }
        });

        // Assuming `image` is a File object or similar

        const response = await axios
            .post(`/api/league/${id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // This should be 'multipart/form-data' for FormData
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                setValue('tournamentDetails', res.data.data);

                // getTournamentHub(id);
                return res.data.data;
            })
            .catch((err) => {
                console.log('ERR4', err);
                setValue('isLoading', false);
                throw err;
            });
        return response;
    };
    const getTournamentEventList = async (id) => {
        setValue('isLoading', true);
        const response = await axios
            .get(`/api/tournament/events/${id}`, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });
        return response;
    };
    const uploadScoreToDupr = async (matchIds) => {
        const params = { matchIds };
        setValue('isLoading', true);
        const response = await axios
            .post(`/api/matches/upload/dupr`, params, {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });
        return response;
    };
    const sendEventEmailInvites = async (players_file, type, event_id) => {
        const formData = new FormData();

        formData.append('players_file', players_file);
        formData.append('type', type);
        formData.append('event_id', event_id);

        setValue('isLoading', true);
        const response = await axios
            .post(`/api/event/invite/email`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // This should be 'multipart/form-data' for FormData
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });
        return response;
    };
    const uploadRoster = async (roster, division_id, event_id) => {
        const formData = new FormData();

        formData.append('roster', roster);
        formData.append('division_id', division_id);
        formData.append('event_id', event_id);

        setValue('isLoading', true);
        const response = await axios
            .post(`/api/roster/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // This should be 'multipart/form-data' for FormData
                }
            })
            .then((res) => {
                setValue('isLoading', false);
                setLeagueValue('eventTeams', res.data.teams);
                return res.data.data;
            })
            .catch((err) => {
                setValue('isLoading', false);
            });
        return response;
    };
    const getLeagueCourts = async (league_id) => {
        const response = await axios
            .get(`/api/courts`, {
                params: {
                    league_id
                }
            })
            .then((res) => {
                setValue('leagueCourts', res.data.data);
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const bulkCreateCourts = async (league_id, num_of_courts) => {
        const params = { league_id, num_of_courts };
        const response = await axios
            .post(`/api/courts/bulk`, params)
            .then((res) => {
                setValue('leagueCourts', res.data.data);
                const updatedTournamentDetails = {
                    ...state.tournamentDetails,
                    courts: res.data.data
                };
                setValue('tournamentDetails', updatedTournamentDetails);
                return res.data.data;
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const deleteDivisionPools = async (division_id) => {
        const response = await axios
            .delete(`/api/division/pools/${division_id}`)
            .then((res) =>
                // setValue('leagueCourts', res.data.data);
                setEventPools([])
            )
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const deleteDivisionBracket = async (division_id) => {
        const response = await axios
            .delete(`/api/division/bracket/${division_id}`)
            .then(() => {
                setMatches([]);
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const deleteCourt = async (id) => {
        const response = await axios
            .delete(`/api/court/${id}`)
            .then(() => {
                const newCourtsList = state.leagueCourts.filter((item) => item.id !== id);
                setValue('leagueCourts', newCourtsList);
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const getCourt = async (id) => {
        const response = await axios
            .get(`/api/court/${id}`)
            .then((res) => {
                setValue('court', res.data.data);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const getCourtQueueItem = async (id) => {
        const response = await axios
            .get(`/api/court-queue/${id}`)
            .then((res) => {
                setValue('courtQueueItem', res.data.data);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const updateCourt = async (id, params) => {
        const response = await axios
            .put(`/api/court/${id}`, params)
            .then((res) => {
                setValue('court', res.data.data);
                const courtIndex = state.leagueCourts.findIndex((court) => court.id === id);
                if (courtIndex !== -1) {
                    // Create a new array with the updated court data
                    const updatedCourts = [...state.leagueCourts];
                    updatedCourts[courtIndex] = res.data.data;
                    // Return the new state
                    setValue('leagueCourts', updatedCourts);
                }
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const updateMatchCourt = async (id, court) => {
        const response = await axios
            .put(`/api/court/match/${id}`, { court_id: court?.id })
            .then((res) => {
                const newState = {
                    ...state.courtQueueItem,
                    match: {
                        ...state.courtQueueItem.match,
                        court
                    }
                };
                setValue('courtQueueItem', newState);
                const newLeagueCourts = state.leagueCourts.map((item) => {
                    if (item.id === court?.id) {
                        item.is_available = 0;
                    }
                    return item;
                });
                setValue('leagueCourts', newLeagueCourts);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const updateSlug = async (params) => {
        const response = await axios
            .post(`/api/league/slug/update`, params)
            .then((res) => {
                if (res.data.success) {
                    console.log(res.data);
                    const newState = { ...state.tournamentDetails, slug: res.data.data.slug };
                    setValue('tournamentDetails', newState);
                    // setLeagueLeaderboard(res.data.leaderboard);
                    updateValue('errors', { newSlug: null });
                    return res.data.data;
                }
                updateValue('errors.newSlug', res.data.message);
                throw res;
            })
            .catch((err) => {
                console.log('err', err);
                // updateValue('errors', { newSlug: err.response.data.message });

                throw err;
            });
        return response;
    };

    const getCourtQueue = async (league_id, active, search) => {
        updateValue('loading.courtQueue', true);
        const response = await axios
            .get(`/api/court-queue`, {
                params: {
                    league_id,
                    active,
                    search,
                    queue_filter: state.queueFilters
                }
            })
            .then((res) => {
                updateValue('loading.courtQueue', false);
                setValue('courtQueue', res.data.data);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                updateValue('loading.courtQueue', false);
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const reorderQueue = async (order) => {
        const response = await axios
            .post(`/api/court-queue/reorder`, { order })
            .then(
                (res) =>
                    // setValue('courtQueue', res.data.data);
                    // setValue('courtQueueLastPage', res.data.data.last_page);

                    res.data.data // Assuming you want to return the mapped array
            )
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const getTeam = async (id) => {
        const response = await axios
            .get(`/api/team/${id}`)
            .then(
                (res) => res.data.team // Assuming you want to return the mapped array
            )
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const editTeam = async (params) => {
        try {
            const response = await axios.put(`/api/team/${params?.team_id}`, params);
            const updatedTeam = response.data.team;

            // Use the replaceArrayItem action to update the eventTeams array
            replaceArrayItem('eventTeams', updatedTeam.id, updatedTeam);
        } catch (err) {
            console.error('Error updating team:', err); // Handle or log the error
            throw err;
        }
    };

    const getTournamentAdmins = async (id) => {
        const response = await axios
            .get(`/api/tournament/admins/${id}`)
            .then((res) => {
                setValue('tournamentAdmins', res.data.data);

                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });

        return response;
    };
    const addEventAdmin = async (id, user_id) => {
        const response = await axios
            .post(`/api/tournament/admins/${id}`, { user_id })
            .then((res) => {
                setValue('tournamentAdmins', [...state.tournamentAdmins, res.data.data]);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const deleteEventAdmin = async (id) => {
        const response = await axios
            .delete(`/api/tournament/admins/${id}`)
            .then((res) => {
                const newValue = state.tournamentAdmins.filter((item) => item.id !== id);
                setValue('tournamentAdmins', newValue);
                // setValue('courtQueueLastPage', res.data.data.last_page);
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };

    const getEventTickets = async (product_id) => {
        updateValue('loading.eventTickets', true);

        const response = await axios
            .get(`/api/product/tickets`, {
                params: {
                    product_id
                }
            })
            .then((res) => {
                setValue('eventTickets', res.data.data);
                updateValue('loading.eventTickets', false);

                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                updateValue('loading.eventTickets', false);
                console.error(err); // Handle or log the error
            });

        return response;
    };
    const getEventTicket = async (product_id) => {
        updateValue('loading.eventTicket', true);

        const response = await axios
            .get(`/api/product/ticket/${product_id}`)
            .then((res) => {
                setValue('eventTicket', res.data.data);
                updateValue('loading.eventTicket', false);

                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                updateValue('loading.eventTicket', false);
                console.error(err); // Handle or log the error
            });

        return response;
    };
    const addEventTicket = async (params) => {
        const response = await axios
            .post(`/api/product/tickets`, params)
            .then((res) => {
                setValue('eventTickets', [...state.eventTickets, res.data.data]);
                const updatedTournamentDetails = {
                    ...state.tournamentDetails,
                    event_tickets: [...state.tournamentDetails.event_tickets, res.data.data]
                };
                setValue('tournamentDetails', updatedTournamentDetails);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const deleteEventTicket = async (id) => {
        const response = await axios
            .delete(`/api/product/ticket/${id}`)
            .then((res) => {
                const newState = state.eventTickets.filter((item) => item?.id !== id);
                setValue('eventTickets', newState);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const updateEventTicket = async (id, params) => {
        const response = await axios
            .put(`/api/product/ticket/${id}`, params)
            .then((res) => {
                const updatedTickets = state.eventTickets.map((ticket) => (ticket.id === id ? res.data.data : ticket));
                console.log('res', res.data.data);
                setValue('eventTickets', updatedTickets);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const printMatches = async (params) => {
        updateValue('loading.printMatches', true);

        await axios
            .get('/api/tournament/matches/printout', {
                params,
                responseType: 'blob' // Ensure response is treated as a blob
            })
            .then((response) => {
                console.log('response', response);
                const file = new Blob([response.data], { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);

                // Open the PDF in a new window and print it
                const pdfWindow = window.open(fileURL);
                pdfWindow.onload = function () {
                    pdfWindow.print();
                };
            })
            .catch((err) => {
                console.log('err', err);
                updateValue('errors.printMatches', 'No matches to print');
                throw err;
            })
            .finally(() => {
                updateValue('loading.printMatches', false);
            });
    };
    const getEventDiscounts = async (product_id) => {
        updateValue('loading.eventDiscounts', true);

        const response = await axios
            .get(`/api/product/discounts`, {
                params: {
                    product_id
                }
            })
            .then((res) => {
                setValue('eventDiscounts', res.data.data);
                updateValue('loading.eventDiscounts', false);

                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                updateValue('loading.eventDiscounts', false);
                console.error(err); // Handle or log the error
            });

        return response;
    };
    const addEventDiscount = async (params) => {
        const response = await axios
            .post(`/api/product/discounts`, params)
            .then((res) => {
                setValue('eventDiscounts', [...state.eventDiscounts, res.data.data]);
                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const deleteEventDiscount = async (id) => {
        const response = await axios
            .delete(`/api/product/discount/${id}`)
            .then((res) => {
                const newState = state.eventDiscounts.filter((item) => item?.id !== id);
                setValue('eventDiscounts', newState);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
            });
        return response;
    };
    const updateEventDiscount = async (id, params) => {
        const response = await axios
            .put(`/api/product/discount/${id}`, params)
            .then((res) => {
                const updatedDiscounts = state.eventDiscounts.map((discount) => (discount.id === id ? res.data.data : discount));
                console.log('res', res.data.data);
                setValue('eventDiscounts', updatedDiscounts);
                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                console.error(err); // Handle or log the error
                throw err;
            });
        return response;
    };
    const getEventDiscount = async (discount_id) => {
        updateValue('loading.eventDiscount', true);

        const response = await axios
            .get(`/api/product/discount/${discount_id}`)
            .then((res) => {
                setValue('eventDiscount', res.data.data);
                updateValue('loading.eventDiscount', false);

                // setValue('courtQueueLastPage', res.data.data.last_page);

                return res.data.data; // Assuming you want to return the mapped array
            })
            .catch((err) => {
                updateValue('loading.eventDiscount', false);
                console.error(err); // Handle or log the error
            });

        return response;
    };

    return (
        <TournamentsContext.Provider
            value={{
                ...state,
                setValue,
                setTournamentValue,
                createTournament,
                getTournamentHub,
                editTournament,
                getLeagueHub,
                editLeague,
                getTournamentEventList,
                uploadScoreToDupr,
                getEventHubDashboard,
                sendEventEmailInvites,
                bulkCreateCourts,
                getLeagueCourts,
                deleteCourt,
                updateSlug,
                getCourtQueue,
                uploadRoster,
                deleteDivisionPools,
                deleteDivisionBracket,
                getTeam,
                editTeam,
                reorderQueue,
                selectedEvents,
                setSelectedEvents,
                getTournamentAdmins,
                addEventAdmin,
                deleteEventAdmin,
                getCourt,
                updateCourt,
                updateValue,
                getCourtQueueItem,
                updateMatchCourt,
                getEventTickets,
                addEventTicket,
                deleteEventTicket,
                getEventTicket,
                updateEventTicket,
                printMatches,
                getEventDiscounts,
                addEventDiscount,
                deleteEventDiscount,
                updateEventDiscount,
                getEventDiscount,
                mergeDivisions
            }}
        >
            {children}
        </TournamentsContext.Provider>
    );
}
