/* eslint-disable prettier/prettier */
import { Button, Grid, InputAdornment, TextField, IconButton, Typography, Stack } from '@mui/material';
import { Add, Remove, BorderColor, Delete } from '@mui/icons-material';
import { useFormik } from 'formik';
import useClubs from 'hooks/useClubs';
import FormSection from 'views/admin/Events/create-event/components/FormSection';
import { gridSpacing } from 'store/constant';
import { useEffect, useState } from 'react';
import actionSnackbar from 'ui-component/actionSnackbar';
import MembershipList from '../../../../../components/MembershipList';
import SaveFooter from 'views/admin/AdminHub/components/SaveFooter';
import FormComponents from 'ui-component/FormComponents';
import CardModal from 'ui-component/CardModal';
import { useNavigate, useParams } from 'react-router-dom';

const MembershipDetails = ({ clubId }) => {


    const { membershipDetails, getClubMembership, updateClubMembership, deleteMembership } = useClubs();

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const toggleDeleteModal = () => {
        setDeleteModalOpen(!deleteModalOpen)
    }


    const initialValues = {
        id: membershipDetails?.id || undefined,
        name: membershipDetails?.name || '',
        description: membershipDetails?.description || '',
        club_id: membershipDetails?.club_id || '',
        short_code: membershipDetails?.short_code || '',
        features: membershipDetails?.features || [], // Initialize with a default feature
        pricing: membershipDetails?.pricing?.map((item) => ({ ...item, price: item.price / 100 })) || [] // Initialize with default pricing
    };

    const formik = useFormik({
        initialValues,
        // validationSchema,
        onSubmit: (values, { resetForm }) => {
            const updatedValues = { ...values, pricing: values.pricing?.map((item) => ({ ...item, price: item.price * 100 })) };

            updateClubMembership(updatedValues)
                .then(() => {
                    actionSnackbar(true, 'Membership updated');
                })
                .catch(() => {
                    actionSnackbar(false, 'Failed to update membership');
                });
        }
    });

    useEffect(() => {
        formik.resetForm({
            values: initialValues
        });
    }, [membershipDetails]);



    const handleAddFeature = () => {
        formik.setFieldValue('features', [...formik.values.features, { feature_description: '' }]);
    };

    const handleRemoveFeature = (index) => {
        const newFeatures = formik.values.features.filter((_, i) => i !== index);
        formik.setFieldValue('features', newFeatures);
    };

    const handleFeatureChange = (index, event) => {
        const newFeature = formik.values.features.map((item, i) => (i === index ? { ...item, 'feature_description': event.target.value } : item));
        formik.setFieldValue('features', newFeature);
    };

    const handleAddPricing = () => {
        const hasMonthlyPricing = formik.values.pricing.some((item) => item.term === 'monthly');


        formik.setFieldValue('pricing', [...formik.values.pricing, { term: hasMonthlyPricing ? 'annual' : 'monthly', price: '' }]);
        // setPricing([...pricing, { term: '', price: '' }]);
    };

    const handleRemovePricing = (index) => {
        const newPricing = formik.values.pricing.filter((_, i) => i !== index);
        formik.setFieldValue('pricing', newPricing);
    };

    const handlePricingChange = (index, field, value) => {
        const newPricing = formik.values.pricing.map((item, i) => (i === index ? { ...item, [field]: value } : item));
        formik.setFieldValue('pricing', newPricing);
    };

    const handleCancel = () => {
        console.log('cancel');
    };
    const navigate = useNavigate();
    const handleDelete = () => {
        deleteMembership(membershipDetails?.id).then(() => {
            toggleDeleteModal();
            navigate(`/club/${clubId}/Memberships`)
            actionSnackbar(true, 'Membership removed')

        })
    }

    const sectionProps = {
        icon: <BorderColor />, // Replace with the actual icon component you want to use
        title: 'Membership Details',
        descriptor: 'Add the necessary details for your customers to know what to expect from this membership',
        showDelete: true,
        deleteAction: () => toggleDeleteModal(),
        inputs: [
            {
                type: 'text',
                id: 'name',
                label: 'Membership Name',
                placeholder: 'Pro Tier',
                value: formik.values.name,
                onChange: formik.handleChange,
                touched: formik.touched.name,
                onBlur: formik.handleBlur,
                error: formik.errors.name
            },
            {
                type: 'text',
                id: 'short_code',
                label: 'Short Code',
                value: formik.values.short_code,
                onChange: formik.handleChange,
                touched: formik.touched.short_code,
                onBlur: formik.handleBlur,
                error: formik.errors.short_code
            },
            {
                type: 'text',
                id: 'description',
                label: 'Description',
                placeholder: 'Be clear and descriptive.',
                multiline: true,
                value: formik.values.description,
                onChange: formik.handleChange,
                touched: formik.touched.description,
                onBlur: formik.handleBlur,
                error: formik.errors.description
            }
        ]
    };

    const featuresSection = {
        icon: <BorderColor />, // Replace with the actual icon component you want to use
        title: 'Features & Pricing',
        descriptor: 'Add what value the player will find with this membership',
        inputs: [
            {
                type: 'component',
                component: (
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom mb={2}>
                            Pricing
                        </Typography>
                        <Grid container spacing={gridSpacing} alignItems="center">
                            {formik.values.pricing.map((item, index) => (
                                <Grid item key={index} xs={12}>
                                    <Grid container spacing={gridSpacing} alignItems="center">
                                        <Grid item xs={4}>
                                            <FormComponents
                                                input={{
                                                    type: 'select',
                                                    value: item.term,
                                                    label: 'Term',
                                                    onChange: (event) => handlePricingChange(index, 'term', event.target.value),
                                                    onBlur: formik.handleBlur,
                                                    options: [
                                                        { value: "monthly", label: "Monthly" },
                                                        { value: "annual", label: "Annual" },
                                                    ]
                                                }}
                                                formik={formik}
                                            />

                                        </Grid>
                                        <Grid item xs={7}>
                                            <FormComponents
                                                input={{
                                                    type: 'currency',
                                                    value: item.price,
                                                    onChange: (values) => handlePricingChange(index, 'price', values.value),
                                                    label: 'Price',
                                                    placeholder: 'Price'
                                                }}
                                                formik={formik}
                                            />

                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemovePricing(index)}
                                                disabled={formik.values.pricing.length === 1}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            {!(
                                formik.values.pricing.some((item) => item.term === 'monthly') &&
                                formik.values.pricing.some((item) => item.term === 'annual')
                            ) && (
                                    <Grid item xs={12}>
                                        <Button variant="outlined" color="primary" startIcon={<Add />} onClick={handleAddPricing}>
                                            Add Pricing
                                        </Button>
                                    </Grid>
                                )}
                        </Grid>
                    </Grid>
                )
            },
            {
                type: 'component',
                component: (
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom mb={2} mt={2}>
                            Key Features
                        </Typography>
                        <Grid container spacing={2} alignItems="center">
                            {formik.values.features.map((feature, index) => (
                                <Grid item xs={12} key={index}>
                                    <Grid container spacing={gridSpacing}>
                                        <Grid item xs={11}>
                                            <TextField
                                                fullWidth
                                                placeholder={`Feature ${index + 1}`}
                                                value={feature.feature_description}
                                                onChange={(event) => handleFeatureChange(index, event)}
                                                multiline
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleRemoveFeature(index)}
                                                disabled={formik.values.features.length === 1}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))}
                            <Grid item>
                                <Button variant="outlined" color="primary" startIcon={<Add />} onClick={handleAddFeature}>
                                    Add Feature
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        ]
    };

    return (
        <Grid container spacing={gridSpacing} sx={{ paddingBottom: 10 }}>

            {membershipDetails && (
                <Grid item xs={12} lg={12} marginTop={{ xs: 2, md: 0 }}>
                    <Grid container spacing={gridSpacing} mt={2} pl={1}>
                        <form onReset={formik.handleReset} onSubmit={formik.handleSubmit}>
                            <FormSection {...sectionProps} formik={formik} />
                            <FormSection {...featuresSection} formik={formik} />
                            <SaveFooter handleCancel={handleCancel} />
                        </form>
                    </Grid>
                </Grid>
            )}
            <CardModal title="Are you sure?" content="Deleting this membership will impact any account with this membership types" actionText="Delete" actionColor='error' showCancel open={deleteModalOpen} toggleModal={toggleDeleteModal} submitAction={() => handleDelete()} />
        </Grid>
    );
};

export default MembershipDetails;
