/* eslint-disable no-nested-ternary */
import { lazy, useEffect } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import Loadable from 'ui-component/Loadable';
import BothRoutes from './BothRoutes';
import useAuth from 'hooks/useAuth';
import AppRoutes from './AppRoutes';
import HelpRoutes from './HelpRoutes';
import SubdomainRoutes from './SubdomainRoutes'; // Add this for subdomain-specific routes
import useClubs from 'hooks/useClubs';

const PagesLanding = Loadable(lazy(() => import('views/pages/landing')));
const Error = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { isLoggedIn, setSubdomain } = useAuth();
    const { getDomainClub, domainclub } = useClubs();
    const hostname = window.location.hostname;
    const split = hostname.split('.');

    // Check for subdomain while considering 'www' as not a subdomain
    let subdomain = null;
    let isSubdomain = false;
    const isLocal = process.env.REACT_APP_NODE_ENV === 'development';

    if (split.length > (isLocal ? 1 : 2)) {
        // Check if the first part is 'www' and exclude it from the subdomain check
        if (split[0].toLowerCase() !== 'www') {
            subdomain = split[0];
            isSubdomain = true;
        }
    } else {
        isSubdomain = false;
    }

    useEffect(() => {
        getDomainClub(subdomain);
        setSubdomain(subdomain);
    }, [subdomain]);

    const mainRoutes = [
        {
            path: '/',
            element: isSubdomain ? <Navigate to="/c" /> : isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/comp/tournament" />
        },
        { path: '*', element: <Error /> },
        AuthenticationRoutes,
        LoginRoutes,
        MainRoutes,
        BothRoutes,
        AdminRoutes,
        AppRoutes,
        HelpRoutes,
        SubdomainRoutes
    ];

    return useRoutes(mainRoutes);
}
