/* eslint-disable prettier/prettier */
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// assets
import PoolsTable from 'views/components/poolsTable.component';
import TeamsTable from 'views/components/teamsTable.component';

const Pools = ({ eventPools, setSelectedPool, selectedPool, setTab, eventId, eventName, handleGenerateDrawer, poolAction }) => (
    <Grid item xs={12}>
        {eventPools?.length > 0 ?
            <PoolsTable eventPools={eventPools} setActiveMainTab={setTab} setSelectedPool={setSelectedPool} poolAction={poolAction} />
            : <TeamsTable eventId={eventId} eventName={eventName} handleGenerateDrawer={handleGenerateDrawer} />}
    </Grid>
);

export default Pools;