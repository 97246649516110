/* eslint-disable react/no-this-in-sfc */
import { Button, FormHelperText, Grid, InputLabel, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useFormik } from 'formik';
import useAuth from 'hooks/useAuth';
import { useEffect, useState } from 'react';
import { gridSpacing } from 'store/constant';
import CardModal from 'ui-component/CardModal';
import FormComponents from 'ui-component/FormComponents';
import * as Yup from 'yup';
import moment from 'moment';
import PlayerCell from 'views/components/playerCell.component';
import { current } from '@reduxjs/toolkit';
import CurrencyFormat from 'react-currency-format';
import useClubs from 'hooks/useClubs';
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import actionSnackbar from 'ui-component/actionSnackbar';
import useEvents from 'hooks/useEvents';
import FieldSection from 'ui-component/FieldSection';
import { EmojiEvents } from '@mui/icons-material';

const MembershipCheckoutModal = ({ open, toggleModal, membership, price, clientSecret, stripeAccount, stripeInvoiceId, subId }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK, { stripeAccount });
    const { user } = useAuth();
    const { intentToken, newPaymentIntent, updatePaymentIntent, setValue, completeMembership } = useClubs();
    const { reserveCourt } = useEvents();
    const [currentTab, setCurrentTab] = useState(0);
    const handleCompletion = () => {
        console.log('completed');
    };
    const CheckoutForm = () => {
        const stripe = useStripe();
        const elements = useElements();
        const [paymentError, setPaymentError] = useState('');
        const [processing, setProcessing] = useState(false);

        const handlePaymentError = (error) => {
            if (error.type === 'card_error' || error.type === 'validation_error') {
                setPaymentError(error.message);
            } else {
                setPaymentError('An unexpected error occurred.');
            }
            actionSnackbar(false, 'Checkout Error');
        };
        const handleSubmit = async (event) => {
            event.preventDefault();
            setProcessing(true);

            if (!stripe || !elements) {
                return;
            }

            try {
                const paymentResult = await stripe.confirmPayment({
                    elements,
                    redirect: 'if_required',
                    confirmParams: {
                        // Make sure to change this to your payment completion page
                        // return_url: `${window.location.origin}/completion`
                    }
                });

                if (paymentResult.error) {
                    // Handle errors returned by Stripe
                    handlePaymentError(paymentResult.error);
                    setProcessing(false);
                } else {
                    completeMembership(membership?.id, price?.id, subId, stripeInvoiceId, paymentResult)
                        .then(() => {
                            setProcessing(false);
                            toggleModal();
                            setValue('intentToken', null);
                            // Proceed with checkout completion
                            setPaymentError('');
                            actionSnackbar(true, 'Sign Up Success');
                        })
                        .catch(() => {
                            actionSnackbar(false, 'Uh oh, something went wrong');
                        });
                }
            } catch (error) {
                // Handle other errors (e.g., network errors)
                handlePaymentError(error);
            }
        };

        const options = {
            layout: {
                type: 'tabs',
                defaultCollapsed: false
            }
        };

        return (
            <form onSubmit={handleSubmit}>
                <CardModal
                    open={open}
                    title="Purchase Membership"
                    toggleModal={toggleModal}
                    content={
                        <Grid container>
                            <Grid item xs={12}>
                                <OrderSummary />
                            </Grid>
                            <Grid item xs={12}>
                                <PaymentElement options={options} id="payment-element" />
                            </Grid>
                        </Grid>
                    }
                    actionText="Checkout"
                    showCancel
                    submitAction={handleSubmit}
                    cancelAction={() => setCurrentTab(0)}
                    loading={processing}
                />
            </form>
        );
    };
    const OrderSummary = () => (
        <TableContainer>
            <Typography variant="h4"> Order Summary </Typography>

            <Table sx={{ minWidth: 'auto' }} size="small" aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell />
                    </TableRow>
                    <TableRow>
                        <TableCell>{membership?.name}</TableCell>
                        <TableCell align="right">
                            <Typography variant="subtitle1">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={price?.price / 100}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                />
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell sx={{ borderBottom: 'none' }}>
                            <Typography variant="subtitle1">Total</Typography>
                        </TableCell>
                        <TableCell align="right" sx={{ borderBottom: 'none' }}>
                            <Typography variant="subtitle1">
                                <CurrencyFormat
                                    decimalScale={2}
                                    fixedDecimalScale
                                    value={price?.price / 100}
                                    displayType="text"
                                    thousandSeparator
                                    prefix="$"
                                />
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );

    const getContent = (tab) => {
        switch (tab) {
            case 0:
                return (
                    <Grid>
                        <Elements stripe={stripePromise} options={{ clientSecret }}>
                            <CheckoutForm />
                        </Elements>
                    </Grid>
                );
            default:
                return <></>;
        }
    };
    if (currentTab === 0) {
        return (
            <CardModal
                open={open}
                title="Purchase Membership"
                toggleModal={toggleModal}
                content={getContent(currentTab)}
                actionText="Purchase"
                showCancel
                submitAction={handleCompletion}
            />
        );
    }
    return (
        <Grid>
            <Elements stripe={stripePromise} options={{ clientSecret: intentToken?.client_secret }}>
                <CheckoutForm />
            </Elements>
        </Grid>
    );
};

export default MembershipCheckoutModal;
