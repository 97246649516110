/* eslint-disable prettier/prettier */
// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { FacilityProvider } from 'contexts/FacilityContext';

// auth provider

// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { GroupProvider } from 'contexts/GroupsContext';
import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import { PostProvider } from 'contexts/PostsContext';
import { EventsProvider } from 'contexts/EventsContext';
import { NotificationsProvider } from 'contexts/NotificationsContext';
import { MessagesProvider } from 'contexts/MessagesContext';
import { LeaguesProvider } from 'contexts/LeaguesContext';
import { UsersProvider } from 'contexts/UsersContext';
import { ClubsProvider } from 'contexts/ClubsContext';
import { TournamentsProvider } from 'contexts/TournamentsContext';
import { HelpCenterProvider } from 'contexts/HelpCenterContext';
import { InternalToolsProvider } from 'contexts/InternalToolsContext';
import { FormProvider } from 'contexts/FormsContext';
import { ShopProvider } from 'contexts/ShopContext';
// import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    if (process.env.REACT_APP_NODE_ENV === 'production') {
        console.log = () => { };
        console.error = () => { };
        console.warn = () => { };
        console.info = () => { };
    }

    return (
        <ThemeCustomization>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <UsersProvider>
                                <FacilityProvider>
                                    <GroupProvider>
                                        <ClubsProvider>
                                            <PostProvider>
                                                <LeaguesProvider>
                                                    <TournamentsProvider>
                                                        <EventsProvider>
                                                            <NotificationsProvider>
                                                                <MessagesProvider>
                                                                    <HelpCenterProvider>
                                                                        <InternalToolsProvider>
                                                                            <FormProvider>
                                                                                <ShopProvider>
                                                                                    <>
                                                                                        <Routes />
                                                                                        <Snackbar />
                                                                                    </>
                                                                                </ShopProvider>
                                                                            </FormProvider>
                                                                        </InternalToolsProvider>
                                                                    </HelpCenterProvider>
                                                                </MessagesProvider>
                                                            </NotificationsProvider>
                                                        </EventsProvider>
                                                    </TournamentsProvider>
                                                </LeaguesProvider>
                                            </PostProvider>
                                        </ClubsProvider>
                                    </GroupProvider>
                                </FacilityProvider>
                            </UsersProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
