import React from 'react';

// material-ui
import { Avatar, Typography, Grid, Stack } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';

// project imports
import PlayerRatings from './playerRatings.component';
import { Link } from 'react-router-dom';
import { FiberManualRecord } from '@mui/icons-material';
import calculateAge from 'utils/calculateAge';

const PlayerCell = ({
    user,
    mt = 1,
    isSub = 0,
    isAbsent = 0,
    backgroundColor,
    padding = 0,
    borderRadius = 0,
    isAdmin = false,
    userAttributes
}) => {
    const theme = useTheme();
    const useStyles = makeStyles({
        profileLink: {
            textDecoration: 'none',
            color: theme.palette.grey[700],
            '&:hover': {
                textDecoration: 'underline'
            }
        },
        playerInfo: {
            display: 'flex',
            alignItems: 'center'
        },
        dot: {
            width: 4,
            height: 4,
            borderRadius: '50%',
            backgroundColor: theme.palette.grey[400],
            margin: '0 8px'
        }
    });
    const styles = useStyles();

    // if (!userAttributes) {
    if (user?.user_attributes) {
        userAttributes = user.user_attributes;
    }
    // }

    const playerName = userAttributes?.name || user?.name;
    const userId = user?.id || userAttributes?.user_id;
    const showAge = isAdmin || userAttributes?.show_age ? calculateAge(userAttributes?.date_of_birth) : null;
    const showGender = isAdmin || userAttributes?.show_gender ? userAttributes?.gender?.charAt(0) : null;

    return (
        <Grid
            container
            mt={mt}
            alignItems="center"
            data-test-id="views/components/playerCell.component"
            sx={{ backgroundColor, padding, borderRadius, flexWrap: 'nowrap' }}
        >
            <Grid item>
                <Avatar alt="User 1" src={userAttributes?.profile_picture_url} sx={{ width: 35, height: 35 }} />
            </Grid>
            <Grid item>
                <Stack direction="row">
                    <Link className={styles.profileLink} to={`/user/social-profile/0/${userId}`}>
                        <Typography ml={1} variant="body1" noWrap display="inline">
                            {playerName}
                        </Typography>
                    </Link>
                    {isSub !== 0 && (
                        <Typography ml={1} variant="subtitle2" noWrap display="inline">
                            - sub
                        </Typography>
                    )}
                    {isAbsent !== 0 && (
                        <Typography ml={1} variant="subtitle2" noWrap display="inline">
                            - withdrawn
                        </Typography>
                    )}
                    {(showAge || showGender) && (
                        <Typography className={styles.playerInfo} variant="body1" noWrap display="inline">
                            <span className={styles.dot} />
                            {showAge && (
                                <>
                                    {showAge}
                                    {showGender && <span className={styles.dot} />}
                                </>
                            )}
                            {showGender}
                        </Typography>
                    )}
                </Stack>
                <PlayerRatings ml={0.5} userAttributes={userAttributes} />
            </Grid>
        </Grid>
    );
};

export default PlayerCell;
