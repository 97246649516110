/* eslint-disable radix */
import React, { useState } from 'react';
import {
    Grid,
    Typography,
    Tabs,
    Tab,
    Link,
    Autocomplete,
    TextField,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableCell,
    Card,
    TableBody
} from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import { useTheme } from '@mui/styles';
import useConfig from 'hooks/useConfig';

const MatchesTable = ({ matches, handleDrawerOpen, setSelectedMatchId }) => (
    <Grid item xs={12} md={9} lg={10}>
        <Card>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width="10%"> Match ID</TableCell>
                            <TableCell width="30%" align="center">
                                Team
                            </TableCell>
                            <TableCell width="10%" />
                            <TableCell width="1%" />
                            <TableCell width="10%" />

                            <TableCell width="30%" align="center">
                                Team
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    {matches?.map((match) => (
                        <TableBody>
                            <TableCell>
                                <Link
                                    component="button"
                                    onClick={() => {
                                        setSelectedMatchId(match.id);
                                        handleDrawerOpen();
                                    }}
                                >
                                    {match.id}
                                </Link>
                            </TableCell>
                            <TableCell align="center">{match?.team_a?.name}</TableCell>
                            {match.status === 'completed' ? (
                                <TableCell>
                                    {match?.match_games?.map((item, index) => {
                                        const wonGame =
                                            parseInt(item.game_scores.find((score) => score.team_id === match?.team_a?.id)?.score) >
                                            parseInt(
                                                match?.match_games[index]?.game_scores.find((score) => score.team_id === match.team_b.id)
                                                    ?.score
                                            );

                                        return (
                                            <Grid
                                                sx={{
                                                    backgroundColor: wonGame ? 'green' : 'red',
                                                    textAlign: 'center',
                                                    paddingX: 1,
                                                    paddingY: 0.25
                                                }}
                                            >
                                                <Typography variant="h5" color="white">
                                                    {item.game_scores.find((score) => score.team_id === match.team_a.id)?.score}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </TableCell>
                            ) : (
                                <TableCell />
                            )}
                            <TableCell>VS</TableCell>
                            {match.status === 'completed' ? (
                                <TableCell>
                                    {match?.match_games?.map((item, index) => {
                                        const wonGame =
                                            parseInt(item.game_scores?.find((score) => score.team_id === match.team_b.id)?.score) >
                                            parseInt(
                                                match.match_games[index]?.game_scores?.find((score) => score.team_id === match.team_a.id)
                                                    ?.score
                                            );

                                        return (
                                            <Grid
                                                sx={{
                                                    backgroundColor: wonGame ? 'green' : 'red',
                                                    textAlign: 'center',
                                                    paddingX: 1,
                                                    paddingY: 0.25
                                                }}
                                            >
                                                <Typography variant="h5" color="white">
                                                    {item.game_scores.find((score) => score.team_id === match.team_b.id)?.score}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </TableCell>
                            ) : (
                                <TableCell />
                            )}
                            <TableCell align="center">{match.team_b?.name}</TableCell>
                        </TableBody>
                    ))}
                </Table>
            </TableContainer>
        </Card>
    </Grid>
);

export default MatchesTable;
