/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@mui/material';
import RoundSelector from './RoundSelector';
import { gridSpacing } from 'store/constant';

import useLeagues from 'hooks/useLeagues';
import MatchesTable from './MatchesTable';
import ProductEmpty from 'views/application/e-commerce/Products/ProductEmpty';
import emptyImage from 'assets/images/empty.png';

const Matches = ({
    eventPools,
    selectedPool,
    setSelectedPool,
    handleDrawerOpen,
    setSelectedMatchId,
    eventId,
    selectedRound,
    setSelectedRound,
    eventBracket,
    selectedEvent,
    isAdmin,
    type
}) => {
    const {
        getPoolRounds,
        getPoolRoundMatches,
        eventMatchups,
        setEventMatchups,
        getBracketRounds,
        getBracketRoundMatches,
        getLeagueGroupMatches,
        poolRounds,
        setValue,
        loading,
        updateValue
    } = useLeagues();

    const [tab, setTab] = useState(0);

    useEffect(() => {
        if (selectedPool) {
            updateValue('loading.matches', true);
            if (selectedPool.name === 'Bracket') {
                getBracketRounds(selectedPool.id).then((res) => {
                    setValue('poolRounds', res);
                    setSelectedRound(res[0]?.id);
                    setTab(0);
                    // updateValue('loading.matches', false);
                });
            } else {
                getPoolRounds(selectedPool?.id).then((res) => {
                    setValue('poolRounds', res);
                    setSelectedRound(res[0]?.id);
                    setTab(0);
                    // updateValue('loading.matches', false);
                });
            }
        } else {
            setValue('poolRounds', []);
            // updateValue('loading.matches', false);
        }
    }, [selectedPool?.id, eventId]);

    useEffect(() => {
        if (selectedPool) {
            updateValue('loading.matches', true);
            if (type === 'tournament') {
                if (selectedPool.name === 'Bracket' && selectedRound) {
                    getBracketRoundMatches(selectedPool.id, selectedRound).then((res) => {
                        if (res) {
                            setEventMatchups(res?.matches);
                        } else {
                            setEventMatchups([]);
                        }
                        updateValue('loading.matches', false);
                    });
                } else {
                    getPoolRoundMatches(selectedRound).then((res) => {
                        if (res) {
                            setEventMatchups(res?.matches);
                        } else {
                            setEventMatchups([]);
                        }
                        updateValue('loading.matches', false);
                    });
                }
            } else if (type === 'league') {
                getLeagueGroupMatches(selectedPool?.id)
                    .then((res) => {
                        if (res) {
                            setEventMatchups(res?.matches);
                        } else {
                            setEventMatchups([]);
                        }
                        updateValue('loading.matches', false);
                    })
                    .catch((err) => {
                        setEventMatchups([]);
                        updateValue('loading.matches', false);
                    });
            }
        } else {
            setEventMatchups([]);
            updateValue('loading.matches', false);
        }
    }, [selectedPool, selectedRound]);

    return (
        <Grid container data-test-id="views/admin/AdminHub/TournamentManager/Matches">
            {eventPools?.length > 0 || poolRounds?.length > 0 || eventBracket ? (
                <>
                    <Grid item xs={12} lg={2} marginRight={{ xs: 0, md: 1 }} marginBottom={{ xs: 2, lg: 0 }}>
                        <RoundSelector
                            eventPools={eventPools}
                            selectedPool={selectedPool}
                            setTab={setTab}
                            poolRounds={poolRounds}
                            setSelectedPool={setSelectedPool}
                            setSelectedRound={setSelectedRound}
                            selectedRound={selectedRound}
                            tab={tab}
                            eventBracket={eventBracket}
                            selectedEvent={selectedEvent}
                            eventId={eventId}
                        />
                    </Grid>
                    <Grid item xs={12} lg={9.8} marginTop={{ xs: 2, md: 0 }}>
                        <MatchesTable
                            matches={eventMatchups}
                            handleDrawerOpen={handleDrawerOpen}
                            setSelectedMatchId={setSelectedMatchId}
                            isAdmin={isAdmin}
                        />
                    </Grid>
                </>
            ) : (
                <Grid mt={4} justifyContent="center" container>
                    <ProductEmpty
                        content="No matches created yet for this division"
                        helperText="Generating a pool or bracket from the Overview tab will automatically create matches"
                        image={emptyImage}
                        imageWidth={{ xs: 200, sm: 300 }}
                    />
                </Grid>
            )}
        </Grid>
    );
};
export default Matches;
