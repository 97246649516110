import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Card, Grid, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import PersonAddTwoToneIcon from '@mui/icons-material/PersonAddTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import { Check, CheckCircle, Delete } from '@mui/icons-material';

const avatarImage = require.context('assets/images/profile', true);

// ==============================|| SOCIAL PROFILE - FOLLOWER CARD ||============================== //

const UserListCard = ({ user, handleUserSelection, clickable = true, showDelete = false, deleteAction, showCheck }) => {
    const theme = useTheme();
    const avatarProfile = user?.user_attributes && user?.user_attributes?.profile_picture_url;

    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <Card
            sx={{
                padding: '16px',
                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                border: '1px solid',
                borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
                '&:hover': {
                    border: `1px solid${theme.palette.primary.main}`
                }
            }}
            onClick={clickable ? handleUserSelection : null}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Avatar alt="User 1" src={avatarProfile} />
                        </Grid>
                        <Grid item xs zeroMinWidth>
                            <Typography
                                variant="h5"
                                component="div"
                                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                {user?.name}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                sx={{ mt: 0.25, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'block' }}
                            >
                                {/* <PinDropTwoToneIcon sx={{ mr: '6px', fontSize: '16px', verticalAlign: 'text-top' }} /> */}
                                {user?.user_attributes?.rating}
                            </Typography>
                        </Grid>
                        {showDelete && (
                            <Grid item>
                                <IconButton onClick={() => deleteAction(user.id)}>
                                    <Delete color="error" />
                                </IconButton>
                            </Grid>
                        )}
                        {showCheck && (
                            <Grid item>
                                <CheckCircle color="success" />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                {/* <Grid item xs={12}>
                    {follow === 2 ? (
                        <Button variant="contained" fullWidth startIcon={<PersonAddTwoToneIcon />}>
                            Follow Back
                        </Button>
                    ) : (
                        <Button variant="outlined" fullWidth startIcon={<PeopleAltTwoToneIcon />}>
                            Followed
                        </Button>
                    )}
                </Grid> */}
            </Grid>
        </Card>
    );
};

UserListCard.propTypes = {
    user: PropTypes.object,
    clickable: PropTypes.bool
};

export default UserListCard;
