/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useMemo, useState, useEffect } from 'react';

// material-ui
import {
    Grid, Typography, Avatar, Button, AvatarGroup
} from '@mui/material';


// project imports

// assets
import PoolsTable from 'views/components/poolsTable.component';
import { SingleEliminationBracket, DoubleEliminationBracket, Match, SVGViewer, createTheme } from '@g-loot/react-tournament-brackets';
import { gridSpacing } from 'store/constant';
import useLeagues from 'hooks/useLeagues';


// const matches = [
//     {
//         id: 19753,
//         nextMatchId: null,
//         tournamentRoundText: '3',
//         startTime: '2021-05-30',
//         state: 'SCHEDULED',
//         participants: []
//     },
//     {
//         id: 19754,
//         nextMatchId: 19753,
//         tournamentRoundText: '2',
//         startTime: '2021-05-30',
//         state: 'SCHEDULED',
//         participants: [
//             {
//                 id: '14754a1a-932c-4992-8dec-f7f94a339960',
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: 'CoKe BoYz',
//                 players: [{ name: 'Jordan' }, { name: 'Austin' }],
//                 picture: 'https://playr-content.s3.us-east-2.amazonaws.com/profile-pictures/242/e7O5IwS62WS7JPCiKItzEtcolvLiqkNLqKl0SN9B.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVW5NOHYDLAR7LJWH%2F20231122%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T012931Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=336d9b581333bb3a2417baa154096a730306b8d8e0e257d0d5df9e8227fb3477'
//             },
//             {
//                 id: 'd8b9f00a-0ffa-4527-8316-da701894768f',
//                 resultText: null,
//                 isWinner: false,
//                 status: null,
//                 name: 'Team 1',
//                 picture: 'teamlogos/client_team_default_logo',
//                 players: [{ name: 'Billy' }, { name: 'Erin' }],
//             },
//         ]
//     },
//     {
//         id: 19755,
//         nextMatchId: 19754,
//         tournamentRoundText: '1',
//         startTime: '2021-05-30',
//         state: 'SCORE_DONE',
//         participants: [
//             {
//                 id: '14754a1a-932c-4992-8dec-f7f94a339960',
//                 resultText: 11,
//                 isWinner: true,
//                 status: 'PLAYED',
//                 name: 'CoKe BoYz',
//                 players: [{ name: 'Jordan' }, { name: 'Austin' }],

//                 picture: 'https://playr-content.s3.us-east-2.amazonaws.com/profile-pictures/242/e7O5IwS62WS7JPCiKItzEtcolvLiqkNLqKl0SN9B.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVW5NOHYDLAR7LJWH%2F20231122%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20231122T012931Z&X-Amz-SignedHeaders=host&X-Amz-Expires=604800&X-Amz-Signature=336d9b581333bb3a2417baa154096a730306b8d8e0e257d0d5df9e8227fb3477'
//             },
//             {
//                 id: 'd16315d4-7f2d-427b-ae75-63a1ae82c0a8',
//                 resultText: 8,
//                 isWinner: false,
//                 status: 'PLAYED',
//                 name: 'Aids Team',
//                 players: [{ name: 'Michael' }, { name: 'Micky' }],
//                 picture: 'teamlogos/client_team_default_logo'
//             }
//         ]
//     },
//     {
//         id: 19756,
//         nextMatchId: 19754,
//         tournamentRoundText: '1',
//         startTime: '2021-05-30',
//         state: 'SCORE_DONE',
//         participants: [
//             {
//                 id: 'd8b9f00a-0ffa-4527-8316-da701894768f',
//                 resultText: 11,
//                 isWinner: true,
//                 status: null,
//                 name: 'Team 1',
//                 picture: 'teamlogos/client_team_default_logo',
//                 players: [{ name: 'Billy' }, { name: 'Erin' }],
//             },
//             {
//                 id: 'd8b9f00a-0ffa-4527-8316-da701894768g',
//                 resultText: 2,
//                 isWinner: false,
//                 status: null,
//                 name: 'Team 1',
//                 picture: 'teamlogos/client_team_default_logo',
//                 players: [{ name: 'Guy' }, { name: 'Charlie' }],
//             }
//         ]
//     },
//     {
//         id: 19757,
//         nextMatchId: 19753,
//         tournamentRoundText: '2',
//         startTime: '2021-05-30',
//         state: 'SCHEDULED',
//         participants: [{
//             id: 'df01fe2c-18db-4190-9f9e-aa63364128fe',
//             resultText: 11,
//             isWinner: true,
//             status: null,
//             name: 'Bosphorus',
//             players: [{ name: 'Jordan' }, { name: 'Austin' }],
//             picture: 'teamlogos/r7zn4gr8eajivapvjyzd'
//         },
//         {
//             id: 'df01fe2c-18db-4190-9f9e-aa63364128fm',
//             resultText: 11,
//             isWinner: true,
//             status: null,
//             name: 'Bosphorus',
//             players: [{ name: 'Jordan' }, { name: 'Austin' }],
//             picture: 'teamlogos/r7zn4gr8eajivapvjyzd'
//         }]
//     },
//     {
//         id: 19758,
//         nextMatchId: 19757,
//         tournamentRoundText: '1',
//         startTime: '2021-05-30',
//         state: 'SCORE_DONE',
//         participants: [
//             {
//                 id: '9397971f-4b2f-44eb-a094-722eb286c59b',
//                 resultText: 4,
//                 isWinner: false,
//                 status: null,
//                 name: 'Crazy Pepes',
//                 players: [{ name: 'Milo' }, { name: 'Conrad' }],
//                 picture: 'teamlogos/client_team_default_logo'

//             },
//             {
//                 id: 'df01fe2c-18db-4190-9f9e-aa63364128fm',
//                 resultText: 11,
//                 isWinner: true,
//                 status: null,
//                 name: 'Bosphorus',
//                 players: [{ name: 'Harry' }, { name: 'Travis' }],
//                 picture: 'teamlogos/r7zn4gr8eajivapvjyzd'
//             }
//         ]
//     },
//     {
//         id: 19759,
//         nextMatchId: 19757,
//         tournamentRoundText: '1',
//         startTime: '2021-05-30',
//         state: 'SCORE_DONE',
//         participants: [
//             {
//                 id: '42fecd89-dc83-4821-80d3-718acb50a30c',
//                 resultText: 3,
//                 isWinner: false,
//                 status: null,
//                 name: 'BLUEJAYS',
//                 players: [{ name: 'Vladir' }, { name: 'Michael' }],
//                 picture: 'teamlogos/client_team_default_logo'
//             },
//             {
//                 id: 'df01fe2c-18db-4190-9f9e-aa63364128fe',
//                 resultText: 11,
//                 isWinner: true,
//                 status: null,
//                 name: 'Bosphorus',
//                 players: [{ name: 'Henry' }, { name: 'Boris' }],
//                 picture: 'teamlogos/r7zn4gr8eajivapvjyzd'
//             }
//         ]
//     }
// ];


const MedalRound = ({ eventId, handleGenerateBracketDrawer }) => {
    const svgWrapper = useMemo(
        () =>
            ({ children, ...props }) =>
            (
                <SVGViewer width={500} height={500} {...props}>
                    {children}
                </SVGViewer>
            ),
        []
    ); // Empty dependency array means it will only be computed once

    const [partySelected, setPartySelected] = useState()
    const [showParty, setShowParty] = useState(false)

    const { getEventBracket, matches } = useLeagues();

    useEffect(() => {
        getEventBracket(eventId)
    }, [eventId])


    const TeamCard = ({ onPartyClick, party, onMouseEnter, won, match }) => (
        <Button onClick={() => onPartyClick(party.id, true)} disabled={!party.id}>
            <Grid container justifyContent="space-between" alignItems="center" onMouseEnter={() => onMouseEnter(party.id)} sx={{ backgroundColor: 'white', padding: 0.25, borderRadius: 2, paddingRight: 2, minHeight: 40, opacity: won || match.state !== 'SCORE_DONE' ? 1 : 0.3, border: showParty && partySelected === party.id ? '2px solid green' : null }}
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <AvatarGroup>

                            {party?.players?.map((player) => (
                                <Avatar sx={{ width: 26, height: 26 }} src={player?.user?.user_attributes?.profile_picture_url} />

                            ))}
                            {/* <Avatar sx={{ width: 26, height: 26 }} />
                            <Avatar sx={{ width: 26, height: 26 }} /> */}
                        </AvatarGroup>
                        <Grid>
                            {party?.players?.length > 0 ?
                                party.players?.map((player, index) => (
                                    <Grid item alignItems="center">
                                        <Grid container alignItems="center">

                                            {/* <Avatar sx={{ width: 26, height: 26 }} src={player?.user?.user_attributes?.profile_picture_url} /> */}
                                            <Typography noWrap variant='h6' ml={1}> {player?.user?.name}</Typography>
                                        </Grid>
                                    </Grid>
                                ))
                                : match.state === 'WALK_OVER' ?
                                    <Typography variant='h5'> BYE </Typography>
                                    :
                                    <Typography variant='h5'> TBD </Typography>}
                        </Grid>
                    </Grid>
                </Grid>
                {match?.match_games?.length > 0 &&
                    match?.match_games?.map((game, index) => (
                        <Grid item key={index}>
                            {game.game_scores.filter((score) => score.team_id === party.id).map((filteredScore, scoreIndex) => (
                                <Typography key={scoreIndex} variant="h4" sx={{ color: match.winner_id === party.id ? 'green' : 'black' }}>
                                    {filteredScore.score}
                                </Typography>
                            ))}
                        </Grid>
                    ))
                }

            </Grid>
        </Button>
    )

    return (
        <Grid item xs={12}>
            {matches?.length > 0 ?
                <SingleEliminationBracket
                    matches={matches}
                    onPartyClick={(party, partyWon) => {
                        if (showParty && party === partySelected) {
                            setShowParty(false)
                        } else {
                            setShowParty(true)
                        }
                        setPartySelected(party);
                    }}

                    matchComponent={({
                        match,
                        onMatchClick,
                        onPartyClick,
                        onMouseEnter,
                        onMouseLeave,
                        topParty,
                        bottomParty,
                        topWon,
                        bottomWon,
                        topHovered,
                        bottomHovered,
                        topText,
                        bottomText,
                        connectorColor,
                        computedStyles,
                        teamNameFallback,
                        resultFallback,
                    }) => (
                        <Grid sx={{
                            display: 'flex', flexDirection: 'column', justifyContent: 'center'
                        }}>
                            <TeamCard onPartyClick={onPartyClick} party={topParty} onMouseEnter={onMouseEnter} won={topWon} match={match} />
                            <Grid sx={{ height: '1px', width: '100%' }} />
                            <TeamCard onPartyClick={onPartyClick} party={bottomParty} onMouseEnter={onMouseEnter} won={bottomWon} match={match} />
                            {/* <Button onClick={() => onPartyClick(topParty.id, true)} disabled={!topParty.id}>
                                <Grid container justifyContent="space-between" alignItems="center" onMouseEnter={() => onMouseEnter(topParty.id)} sx={{ backgroundColor: 'white', padding: 0.25, borderRadius: 2, paddingRight: 2, minHeight: 40, opacity: topWon || match.state !== 'SCORE_DONE' ? 1 : 0.3, border: showParty && partySelected === topParty.id ? '2px solid green' : null }}
                                >
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <AvatarGroup>
                                                <Avatar sx={{ width: 26, height: 26 }} />
                                                <Avatar sx={{ width: 26, height: 26 }} />
                                            </AvatarGroup>
                                            <Grid>
                                                {topParty?.players?.length > 0 ?
                                                    topParty.players?.map((player, index) => (
                                                        <Grid item alignItems="center">
                                                            <Grid container alignItems="center">

                                                                <Typography noWrap variant='h6' ml={1}> {player?.user?.name}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))
                                                    : <Typography variant='h5'> TBD </Typography>}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item >

                                        <Typography variant="h4" sx={{ color: topParty.isWinner ? 'green' : 'black' }}> {topParty.resultText}</Typography>
                                    </Grid>
                                </Grid>
                            </Button> */}


                        </Grid>



                    )
                    }                // theme={WhiteTheme}

                    svgWrapper={svgWrapper}

                />
                : <Grid>
                    <Button onClick={() => handleGenerateBracketDrawer()} variant="contained"> Generate Bracket</Button>

                </Grid>
            }
        </Grid>
    )
}

export default MedalRound;