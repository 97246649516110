/* eslint-disable array-callback-return */
/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    CardContent,
    Chip,
    Divider,
    Drawer,
    Grid,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    useMediaQuery,
    TextField,
    FormControl,
    Typography,
    Box,
    IconButton,
    Button,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableBody,
    Avatar,
    Stack,
    InputAdornment
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Formik, Form } from 'formik';

// third-party

// project imports
import useConfig from 'hooks/useConfig';

import MainCard from 'ui-component/cards/MainCard';
import { appDrawerWidth as drawerWidth, gridSpacing } from 'store/constant';

// assets
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';
import HistoryEduTwoToneIcon from '@mui/icons-material/HistoryEduTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import LabelImportantTwoToneIcon from '@mui/icons-material/LabelImportantTwoTone';
import LabelTwoToneIcon from '@mui/icons-material/LabelTwoTone';
import NewReleasesTwoToneIcon from '@mui/icons-material/NewReleasesTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { PersonOutlineOutlined, Search } from '@mui/icons-material';
import useLeagues from 'hooks/useLeagues';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PlayerCell from 'views/components/playerCell.component';
import CondensedTeamCard from 'ui-component/cards/CondensedTeamCard';
import { dispatch } from 'store';
import { openSnackbar } from 'store/slices/snackbar';

// ==============================|| MAIL DRAWER ||============================== //

const MatchInputDrawer = ({ handleDrawerOpen, openSidebar, selectedMatchId, eventId, type, divisionId, isAdmin = false }) => {
    const theme = useTheme();
    const { borderRadius } = useConfig();
    const { getMatch, updateGameScore, addGameScore, addGameScores, divisionTeamResults, searchDivisionTeams, updateMatchTeams } = useLeagues();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('xl'));
    const [match, setMatch] = useState();
    const [games, setGames] = useState([]);
    const [error, setError] = useState();
    const [search, setSearch] = useState({ team_a: '', team_b: '' });
    const [teamChange, setTeamChange] = useState(false)

    useEffect(() => {
        if (selectedMatchId && eventId) {
            getMatch(selectedMatchId, eventId, type)
                .then((res) => setMatch(res))
                .catch((err) => setError('No Match Found'));
        }
        if (openSidebar) {
            setError();
        }
        setTeamChange(false)
    }, [selectedMatchId, openSidebar]);


    useEffect(() => {
        setGames(
            match?.match_games.map((game) => ({
                ...game,
                autoCalculated: false, // add this line
                game_scores:
                    game.game_scores.length > 0
                        ? game.game_scores
                        : [
                            { team_id: match.team_a_id, score: '', game_id: game.id },
                            { team_id: match.team_b_id, score: '', game_id: game.id }
                        ]
            }))
        );
    }, [match]);

    const updateScore = (gameId, teamId, newScore, performCalculation) => {
        setGames(
            games.map((game) => {
                if (game.id === gameId) {
                    let updatedGameScores = game.game_scores.map((score) => {
                        if (score.team_id === teamId) {
                            return { ...score, score: newScore };
                        }
                        return score;
                    });

                    // Perform auto-calculation on onBlur
                    if (performCalculation && !game.autoCalculated) {
                        const end_score = game.play_until || 11;
                        if (newScore < Number(end_score) - 1) {
                            updatedGameScores = updatedGameScores.map((score) => {
                                if (score.team_id !== teamId) {
                                    return { ...score, score: end_score };
                                }
                                return score;
                            });
                        } else if (newScore >= Number(end_score - 1)) {
                            updatedGameScores = updatedGameScores.map((score) => {
                                if (score.team_id !== teamId) {
                                    return { ...score, score: Number(newScore) + 2 };
                                }
                                return score;
                            });
                        }

                        return {
                            ...game,
                            game_scores: updatedGameScores,
                            autoCalculated: true
                        };
                    }

                    return {
                        ...game,
                        game_scores: updatedGameScores
                    };
                }
                return game;
            })
        );
    };

    const handleScoreChange = (gameId, teamId, newScore) => {
        updateScore(gameId, teamId, newScore, false);
    };

    // New function to be called on onBlur
    const handleScoreBlur = (gameId, teamId, newScore) => {
        updateScore(gameId, teamId, newScore, true);
    };

    const submitScores = () => {
        if (teamChange) {
            updateMatchTeams(selectedMatchId, match?.team_a, match?.team_b).then(() => {
                dispatch(
                    openSnackbar({
                        open: true,
                        message: 'Match teams updated',
                        variant: 'alert',
                        alert: {
                            color: 'success'
                        },
                        close: true
                    })
                );
            })
        }
        addGameScores({ games });

        handleDrawerOpen();
    };

    const handleSearch = (event, team) => {
        const newInputValue = event.target.value;
        setSearch(prevState => ({ ...prevState, [team]: newInputValue }));
        searchDivisionTeams(divisionId, newInputValue, team);
    };
    const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
    const drawerBG = theme.palette.mode === 'dark' ? 'dark.main' : 'grey.50';

    const handleRemoveTeam = (team) => {
        setTeamChange(true)
        if (team === 'team_a') {
            setMatch((prevState) => ({
                ...prevState,
                team_a: null,
                team_a_id: null
            }))
        } else {
            setMatch((prevState) => ({
                ...prevState,
                team_b: null,
                team_b_id: null
            }))
        }
    }
    const addTeam = (team, party) => {
        setTeamChange(true)
        setSearch((prevState) => ({ ...prevState, [team]: '' }))
        if (team === 'team_a') {
            setMatch((prevState) => ({
                ...prevState,
                team_a: party,
                team_a_id: party?.id
            }))
        } else {
            setMatch((prevState) => ({
                ...prevState,
                team_b: party,
                team_b_id: party?.id
            }))
        }
    }


    return (
        <Drawer
            data-test-id="views/admin/AdminHub/TournamentManager/MatchInputDrawer"
            sx={{
                ml: openSidebar ? 3 : 0,
                flexShrink: 0,
                zIndex: 1200,
                overflowX: 'hidden',
                width: { xs: 320, md: 450 },
                '& .MuiDrawer-paper': {
                    height: '100vh',
                    width: { xs: 320, md: 450 },
                    position: 'fixed',
                    border: 'none',
                    borderRadius: '0px'
                }
            }}
            variant="temporary"
            anchor="right"
            open={openSidebar}
            ModalProps={{ keepMounted: true }}
            onClose={handleDrawerOpen}
        >
            {openSidebar && (
                <MainCard
                    sx={{
                        bgcolor: matchDownLG ? 'transparent' : drawerBG,
                        paddingBottom: 150
                    }}
                    border={!matchDownSM}
                    content={false}
                    elevation={16}
                    boxShadow
                    shadow={theme.shadows[6]}
                >
                    <CardContent
                        sx={{
                            height: matchDownSM ? '100vh' : 'auto', // Adjust the height as needed
                            overflowY: 'auto' // Change to 'hidden' to ensure PerfectScrollbar takes control
                        }}
                    >
                        <Grid container spacing={gridSpacing}>
                            {!error ? (
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> {`Match # ${selectedMatchId}`} </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleDrawerOpen()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid>
                                        <Grid mt={2}>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="h5"> Team 1</Typography>
                                                {match?.team_a && isAdmin &&
                                                    <IconButton sx={{ padding: 0 }} onClick={() => handleRemoveTeam('team_a')}>
                                                        <DeleteTwoToneIcon color="error" />
                                                    </IconButton>
                                                }
                                            </Stack>
                                            {match?.team_a ? match?.team_a?.players?.map((player) => (
                                                <PlayerCell
                                                    user={player?.user}
                                                    userAttributes={player?.user?.user_attributes}
                                                    backgroundColor="#E6E7EC"
                                                    padding={1}
                                                    borderRadius={3}
                                                />
                                            )) : isAdmin &&
                                            <FormControl sx={{ width: '100%', mt: 2 }} >
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Search fontSize="small" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => handleSearch(e, 'team_a')}
                                                    placeholder="Search Teams"
                                                    // value={''} // Bind value
                                                    value={search.team_a}
                                                    size="small"
                                                // disabled={team.length >= maxTeamCount}
                                                />
                                                {search.team_a?.length > 0 && (
                                                    <MainCard border={false} elevation={4} content={false} boxShadow sx={{ padding: 2 }} >
                                                        {/* sx={{ backgroundColor: 'white', padding: 1, boxShadow: theme.customShadows.primary }}> */}
                                                        {divisionTeamResults?.team_a?.map((user, index) =>
                                                        // const userExists = team.some((element) => element.id === user.id);
                                                        (
                                                            <Grid key={index} item xs={12}>
                                                                <CondensedTeamCard
                                                                    party={user}
                                                                    onClick={() => addTeam('team_a', user)}
                                                                    sx={{ cursor: 'pointer' }}


                                                                />
                                                            </Grid>
                                                        )
                                                        )}

                                                    </MainCard>
                                                )}
                                            </FormControl>
                                            }

                                            {/* <Grid mt={1} container sx={{ padding: 1, backgroundColor: '#E6E7EC', borderRadius: 3 }}>
                                            <Avatar />
                                            <Grid ml={2}>
                                                <Typography> Jordan Thiel</Typography>
                                                <Typography> 3.37</Typography>
                                            </Grid>
                                        </Grid> */}
                                        </Grid>
                                        <Grid mt={2}>
                                            <Stack direction="row" justifyContent="space-between">
                                                <Typography variant="h5"> Team 2</Typography>
                                                {match?.team_b && isAdmin &&

                                                    <IconButton sx={{ padding: 0 }} onClick={() => handleRemoveTeam('team_b')}>
                                                        <DeleteTwoToneIcon color="error" />
                                                    </IconButton>
                                                }
                                            </Stack>

                                            {match?.team_b ? match?.team_b?.players.map((player) => (
                                                <PlayerCell
                                                    user={player?.user}
                                                    userAttributes={player?.user?.user_attributes}
                                                    backgroundColor="#E6E7EC"
                                                    padding={1}
                                                    borderRadius={3}
                                                />
                                            )) : isAdmin &&
                                            <FormControl sx={{ width: '100%', mt: 2 }} >
                                                <TextField
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <Search fontSize="small" />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                    onChange={(e) => handleSearch(e, 'team_b')}
                                                    placeholder="Search Teams"
                                                    // value={''} // Bind value
                                                    value={search.team_b}
                                                    size="small"
                                                // disabled={team.length >= maxTeamCount}
                                                />
                                                {search?.team_b?.length > 0 && (
                                                    <MainCard border={false} elevation={4} content={false} boxShadow sx={{ padding: 2 }} >
                                                        {/* sx={{ backgroundColor: 'white', padding: 1, boxShadow: theme.customShadows.primary }}> */}
                                                        {divisionTeamResults?.team_b?.map((user, index) =>
                                                        // const userExists = team.some((element) => element.id === user.id);
                                                        (
                                                            <Grid key={index} item xs={12}>
                                                                <CondensedTeamCard
                                                                    party={user}
                                                                    onClick={() => addTeam('team_b', user)}
                                                                    sx={{ cursor: 'pointer' }}


                                                                />
                                                            </Grid>
                                                        )
                                                        )}

                                                    </MainCard>
                                                )}
                                            </FormControl>
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid mt={2}>
                                        <Typography variant="h5"> Scores</Typography>
                                        <Grid>
                                            <Grid container justifyContent="space-evenly" mb={2} mt={2}>
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography variant="h5"> Team 1</Typography>
                                                </Grid>
                                                <Grid item xs={4} />
                                                <Grid item xs={2} textAlign="center">
                                                    <Typography variant="h5"> Team 2</Typography>
                                                </Grid>
                                            </Grid>
                                            {games?.map((game, index) => {
                                                const teamA = game.game_scores.find((score) => score.team_id === match.team_a_id);
                                                const teamB = game.game_scores.find((score) => score.team_id === match.team_b_id);

                                                return (
                                                    <Grid container justifyContent="space-evenly" alignItems="center" mt={2}>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                value={teamA?.score?.toString()}
                                                                onChange={(event) =>
                                                                    handleScoreChange(game.id, teamA?.team_id, event.target.value)
                                                                }
                                                                onBlur={(event) =>
                                                                    handleScoreBlur(game.id, teamA?.team_id, event.target.value)
                                                                }
                                                                inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} textAlign="center">
                                                            <Typography variant="h5"> Game {index + 1}</Typography>
                                                        </Grid>
                                                        <Grid item xs={2} textAlign="center">
                                                            <TextField
                                                                value={teamB?.score?.toString()}
                                                                onChange={(event) =>
                                                                    handleScoreChange(game.id, teamB?.team_id, event.target.value)
                                                                }
                                                                onBlur={(event) =>
                                                                    handleScoreBlur(game.id, teamB?.team_id, event.target.value)
                                                                }
                                                                inputProps={{ min: 0, style: { textAlign: 'center' } }} // the change is here
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} mt={4}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            type="submit"
                                            onClick={submitScores}
                                            startIcon={<PersonOutlineIcon stroke={1.5} size="20px" />}
                                        >
                                            Save
                                        </Button>
                                    </Grid>
                                </Grid>
                            ) : (
                                // <Grid>
                                <Grid item xs={12}>
                                    <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Typography variant="h3"> {`Match # ${selectedMatchId}`} </Typography>
                                        <Box>
                                            <IconButton size="small" onClick={() => handleDrawerOpen()}>
                                                <CancelIcon />
                                            </IconButton>
                                        </Box>
                                    </Grid>
                                    <Grid item mt={4}>
                                        <Typography color="error"> Match not found for this tournament</Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </MainCard>
            )}
        </Drawer>
    );
};

MatchInputDrawer.propTypes = {
    handleDrawerOpen: PropTypes.func,
    openSidebar: PropTypes.bool
};

export default MatchInputDrawer;
