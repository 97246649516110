/* eslint-disable prettier/prettier */
import React from 'react';

// material-ui
import { Grid } from '@mui/material';

// project imports

// assets
import PoolsTable from 'views/components/poolsTable.component';
import TeamsTable from 'views/components/teamsTable.component';

const Pools = ({
    eventPools,
    setSelectedPool,
    selectedPool,
    setTab,
    eventId,
    eventName,
    handleGenerateDrawer,
    setActiveMainTab,
    selectedEvent,
    poolAction,
    type,
    showAdmin = true
}) => (
    <Grid item xs={12} data-test-id="views/admin/AdminHub/TournamentManager/Pools">
        {eventPools?.length > 0 ? (
            <PoolsTable
                eventPools={eventPools}
                setTab={setTab}
                setSelectedPool={setSelectedPool}
                setActiveMainTab={setActiveMainTab}
                poolAction={poolAction}
            />
        ) : (
            <TeamsTable
                showPoolInfo
                eventId={eventId}
                eventName={eventName}
                showAdmin={showAdmin}
                handleGenerateDrawer={handleGenerateDrawer}
                selectedEvent={selectedEvent}
                type={type}
            />
        )}
    </Grid>
);

export default Pools;
