import moment from 'moment';

export const getReadableDateTime = (dateInput, format = 'dateTime', utc = true) => {
    // Use moment to parse the input date string as UTC time
    let inputDate = null;
    if (utc) {
        inputDate = moment.utc(dateInput);
    } else {
        inputDate = moment(dateInput);
    }

    // Use moment to format the date as local time in the desired format
    switch (format) {
        case 'time':
            return inputDate.local().format('h:mm A');
        case 'timeCompact':
            return inputDate.local().format('h:mma');
        case 'date':
            return inputDate.local().format('ddd, MMM D');
        case 'dateTime':
            return inputDate.local().format('ddd, MMM D h:mm A');
        case 'dateShort':
            return inputDate.local().format('MMM D');
        case 'fullDate':
            return inputDate.local().format('MMM D, YYYY');
        case 'appreviatedDate':
            return inputDate.local().format('YYYY-MM-DD');
        case 'numberedDate':
            return inputDate.local().format('YYYYMMDD');
        case 'dayMonthYear':
            return inputDate.local().format('MM/DD/YYYY');
        case 'day':
            return inputDate.local().format('dddd');
        case 'dayTime':
            return inputDate.local().format('ddd h:mma');
        default:
            return inputDate.local().format('MMMM Do h:mm A');
    }
};

export default getReadableDateTime;
