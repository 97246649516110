import * as React from 'react';

// material-ui
import { FormHelperText, InputAdornment, TextField } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker, MobileDatePicker, MobileTimePicker, DatePicker } from '@mui/x-date-pickers';

import '@mui/lab';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

// assets
import DateRangeIcon from '@mui/icons-material/DateRange';

// ==============================|| CUSTOM DATETIME ||============================== //

const CustomDateTime = ({
    type,
    label,
    value,
    onChange,
    onBlur,
    placeholder,
    helperText,
    touched,
    formik,
    id,
    disabled,
    minDate,
    maxDate,
    minutesStep = 1,
    error,

    sx
}) => {
    switch (type) {
        case 'date':
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        value={moment(value) || ''}
                        onChange={(newValue) => {
                            const formattedDate = moment(newValue).format('YYYY-MM-DDTHH:mm:ss');
                            formik.setFieldValue(id, formattedDate);
                        }}
                        label={label}
                        closeOnSelect
                        error={false}
                        slotProps={{
                            textField: {
                                error: false
                            }
                        }}
                        placeholder={placeholder}
                        disabled={disabled}
                        minDate={minDate}
                        maxDate={maxDate}
                        // minDate={new Date()}
                        inputFormat="MM/dd/yyyy"
                        renderInput={(params) => (
                            <>
                                <TextField
                                    {...params}
                                    fullWidth
                                    id={id}
                                    name={id}
                                    defaultValue=""
                                    onBlur={onBlur} // Make sure to call the passed onBlur prop here
                                    sx={{ ...sx }}
                                    error={error}
                                />
                                {touched && error && (
                                    <FormHelperText error id="standard-weight-helper-text--register">
                                        {helperText}
                                    </FormHelperText>
                                )}
                            </>
                        )}
                    />
                </LocalizationProvider>
            );
        case 'time':
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileTimePicker
                        value={moment(value)}
                        disabled={disabled}
                        onChange={(newValue) => {
                            const formattedDate = moment(newValue).format('YYYY-MM-DDTHH:mm:ss');
                            formik.setFieldValue(id, formattedDate);
                        }}
                        label={label}
                        placeholder={placeholder}
                        onError={console.log}
                        minutesStep={minutesStep}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                id={id}
                                name={id}
                                fullWidth
                                onBlur={onBlur} // Make sure to call the passed onBlur prop here
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DateRangeIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    {touched && <FormHelperText error>{helperText}</FormHelperText>}
                </LocalizationProvider>
            );
        default:
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDateTimePicker
                        value={moment(value)}
                        onChange={(newValue) => {
                            const formattedDate = moment(newValue).format('YYYY-MM-DDTHH:mm:ss');
                            formik.setFieldValue(id, formattedDate);
                        }}
                        label={label}
                        placeholder={placeholder}
                        onBlur={onBlur}
                        onError={console.log}
                        disabled={disabled}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                id={id}
                                name={id}
                                onBlur={onBlur} // Make sure to call the passed onBlur prop here
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <DateRangeIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    {touched && <FormHelperText error>{helperText}</FormHelperText>}
                </LocalizationProvider>
            );
    }
};
export default CustomDateTime;
