import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Grid,
    Button,
    Chip,
    Menu,
    MenuItem,
    TableSortLabel
} from '@mui/material';
import PaginationFooter from './PaginationFooter';
import { useTheme } from '@mui/styles';

const EnhancedTableHead = ({ order, orderBy, onRequestSort, theme, headers, showSorting, showActions }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headers?.map((header) => (
                    <TableCell
                        key={header.id}
                        align={header.align || 'left'}
                        padding={header.disablePadding ? 'none' : 'normal'}
                        sortDirection={showSorting && orderBy === header.id ? order : false}
                    >
                        {showSorting ? (
                            <TableSortLabel
                                active={orderBy === header.id}
                                direction={orderBy === header.id ? order : 'asc'}
                                onClick={createSortHandler(header.id)}
                            >
                                {header.label}
                                {orderBy === header.id ? (
                                    <Typography component="span" sx={{ display: 'none' }}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </Typography>
                                ) : null}
                            </TableSortLabel>
                        ) : (
                            header.label
                        )}
                    </TableCell>
                ))}
                {showActions && (
                    <TableCell sortDirection={false} align="center" sx={{ pr: 3 }}>
                        <Typography
                            variant="subtitle1"
                            sx={{ color: theme.palette.mode === 'dark' ? theme.palette.grey[600] : 'grey.900' }}
                        >
                            Action
                        </Typography>
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};

const CustomTable = ({
    title,
    headers,
    data,
    selectable = false,
    handleClick,
    getData,
    page,
    setPage,
    pageLength,
    setPageLength,
    lastPage,
    showFooter = false,
    handleButtonClick,
    buttonText,
    buttonIcon,
    showActions = false,
    actions = [],
    sx = {},
    showSorting = false
}) => {
    const [selectedRow, setSelectedRow] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

    const theme = useTheme();
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [selectedRowId, setSelectedRowId] = useState(null);

    const handleRowClick = (rowIndex, row) => {
        if (selectable) {
            setSelectedRow(rowIndex);
            const id = row.find((item) => item.id === 'id');
            handleClick(id.data);
        }
    };

    const handleMenuClick = (event, row) => {
        event.stopPropagation();
        setMenuAnchorEl(event.currentTarget);
        const idItem = row.find((item) => item.id === 'id');
        setSelectedRowId(idItem.data);
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setMenuAnchorEl(null);
        setSelectedRowId(null);
    };

    const handleMenuItemClick = (event, action) => {
        event.stopPropagation();
        if (selectedRowId) {
            action.onClick(selectedRowId);
        }
        handleClose(event);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = sortConfig.key === property && sortConfig.direction === 'asc';
        setSortConfig({ key: property, direction: isAsc ? 'desc' : 'asc' });
    };

    const sortedData = React.useMemo(() => {
        if (!sortConfig.key || !showSorting) return data;

        const sortedArray = [...data].sort((a, b) => {
            const aValue = a.find((item) => item.id === sortConfig.key).data;
            const bValue = b.find((item) => item.id === sortConfig.key).data;

            if (aValue < bValue) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });

        return sortedArray;
    }, [data, sortConfig, showSorting]);

    return (
        <TableContainer component={Paper} sx={{ ...sx }} elevation={4}>
            {(title || buttonText) && (
                <Grid container justifyContent="space-between" alignItems="center" p={2}>
                    <Grid item>
                        <Typography variant="h4">{title}</Typography>
                    </Grid>

                    {handleButtonClick && buttonText && (
                        <Grid item>
                            <Button startIcon={buttonIcon} variant="contained" onClick={handleButtonClick}>
                                {buttonText}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            )}
            <Table>
                <EnhancedTableHead
                    order={sortConfig.direction}
                    orderBy={sortConfig.key}
                    onRequestSort={handleRequestSort}
                    headers={headers}
                    theme={theme}
                    showSorting={showSorting}
                />
                <TableBody>
                    {sortedData?.map((row, rowIndex) => (
                        <TableRow
                            key={rowIndex}
                            hover={selectable}
                            sx={{ cursor: selectable ? 'pointer' : 'cursor' }}
                            selected={selectable && rowIndex === selectedRow}
                            onClick={() => handleRowClick(rowIndex, row)}
                        >
                            {row.map((cell, cellIndex) => cell.isVisible && <TableCell key={cellIndex}>{cell.data}</TableCell>)}
                            {showActions && (
                                <TableCell align="left">
                                    <Chip
                                        size="small"
                                        label="Actions"
                                        sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                        onClick={(e) => handleMenuClick(e, row)}
                                    />
                                    <Menu
                                        id="menu-popular-card-1"
                                        anchorEl={menuAnchorEl}
                                        open={Boolean(menuAnchorEl)}
                                        keepMounted
                                        onClose={handleClose}
                                        variant="selectedMenu"
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right'
                                        }}
                                        sx={{
                                            '& .MuiMenu-paper': {
                                                boxShadow: theme.customShadows.z1
                                            }
                                        }}
                                    >
                                        {actions.map((action, index) => (
                                            <MenuItem key={index} onClick={(e) => handleMenuItemClick(e, action)}>
                                                <Chip
                                                    size="small"
                                                    label={action.label}
                                                    color={action.chipColor}
                                                    sx={{ borderRadius: '4px', textTransform: 'capitalize' }}
                                                />
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {showFooter && (
                <PaginationFooter lastPage={lastPage} page={page} setPage={setPage} pageLength={pageLength} setPageLength={setPageLength} />
            )}
        </TableContainer>
    );
};

export default CustomTable;
