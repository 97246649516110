/* eslint-disable prettier/prettier */
import { Breadcrumbs, CardContent, Chip, emphasize, Grid, Stack, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import MembershipList from './pages/MembershipList';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { TabContext } from '@mui/lab';
import MembershipDetails from './pages/IndividualMembership/MembershipDetails';
import useClubs from 'hooks/useClubs';
import ReservationRules from './pages/IndividualMembership/ReservationRules';
import MainCard from 'ui-component/cards/MainCard';
import { ExpandMore, Home, Loyalty } from '@mui/icons-material';
import styled from 'styled-components';
import { useTheme } from '@mui/styles';
import MembershipMembers from './pages/IndividualMembership/MembershipMembers';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor = theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06)
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12)
        }
    };
});

const Memberships = ({ clubId, toggleMembershipCreationModal }) => {
    const { id } = useParams();
    const [view, setView] = useState();
    const [queryParams] = useSearchParams();
    const page = queryParams.get('page');
    const { getClubMembership, membershipDetails } = useClubs();
    const navigate = useNavigate();
    const theme = useTheme();

    useEffect(() => {
        if (page) {
            setView(page);
        }
    }, [page]);
    useEffect(() => {
        if (id) {
            if (!page) {
                setView('details');
            }
            getClubMembership(id);
        } else {
            setView();
        }
    }, [id]);

    const getActiveScreen = () => {
        switch (view) {
            case 'details':
                return <MembershipDetails clubId={clubId} />;
            case 'reservation_rules':
                return <ReservationRules />;
            case 'members':
                return <MembershipMembers clubId={clubId} />;
            default:
                return <MembershipList clubId={clubId} />;
        }
    };
    if (!id) {
        return <MembershipList clubId={clubId} toggleMembershipCreationModal={toggleMembershipCreationModal} />;
    }

    return (
        <>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                <StyledBreadcrumb
                    component="a"
                    href="#"
                    label="Memberships"
                    icon={<Home fontSize="small" />}
                    theme={theme}
                    onClick={() => navigate(`/club/${clubId}/Memberships`)}
                />
                <StyledBreadcrumb component="a" href="#" label={membershipDetails?.name} theme={theme} />
            </Breadcrumbs>

            <MainCard
                title={
                    <Stack direction="row" alignItems="center">
                        <Loyalty fontSize="large" sx={{ marginRight: 2 }} />
                        <Typography variant="h3"> {membershipDetails?.name}</Typography>
                    </Stack>
                }
                contentSX={{ marginTop: 0 }}
            >
                <Grid container>
                    <Grid item xs={12}>
                        {view && (
                            <Tabs
                                value={view}
                                onChange={(e, newValue) =>
                                    navigate(`/club/${clubId}/Memberships/${membershipDetails?.id}?page=${newValue}`)
                                }
                                sx={{ display: 'flex', alignSelf: 'flex-end' }}
                                variant="scrollable"
                            >
                                <Tab label="Details" value="details" />
                                {/* <Tab label="Pricing" value="pricing" /> */}
                                <Tab label="Reservation Rules" value="reservation_rules" />
                                <Tab label="Members" value="members" />
                            </Tabs>
                        )}
                    </Grid>
                    <Grid item xs={12} pl={1.5}>
                        {getActiveScreen()}
                    </Grid>
                </Grid>
            </MainCard>
        </>
    );
};

export default Memberships;
