import PropTypes from 'prop-types';
import { useEffect, useState, Fragment } from 'react';

// material-ui
import { Chip, Divider, Grid, List, ListItemButton, ListItemAvatar, ListItemText, Typography, Avatar, AvatarGroup } from '@mui/material';
import moment from 'moment';

// project imports
import UserAvatar from './UserAvatar';

import { useDispatch, useSelector } from 'store';
import { getUsers } from 'store/slices/chat';
import getReadableDateTime from 'utils/get_readable_datetime';
import { useNavigate } from 'react-router-dom';

function formatDate(dateString) {
    const now = moment();
    const messageDate = moment(dateString);
    const diffInMinutes = now.diff(messageDate, 'minutes');
    if (diffInMinutes < 1) {
        return 'Just now';
    }
    if (diffInMinutes < 60) {
        return `${Math.floor(diffInMinutes)}m ago`;
    }
    if (diffInMinutes < 1440) {
        return messageDate.format('LT');
    }
    if (diffInMinutes < 10080) {
        return messageDate.format('ddd');
    }
    return messageDate.format('ll');
}
const avatarSize = 30;

// ==============================|| CHAT USER LIST ||============================== //

const ConversationList = ({ setConversation, data, selectedConversation, handleDrawerOpen, admin }) => {
    const navigate = useNavigate();
    return (
        <List component="nav" data-test-id="views/application/chat/ConversationList">
            {data.map((user) => {
                const UserProfileImages = ({ conversation, otherUser }) => {
                    if (!conversation.conversation.conversation_users) return null;

                    if (conversation.conversation.conversation_users.length === 2) {
                        const imageUrl = otherUser.user.user_attributes.profile_picture_url;
                        return <Avatar sx={{ width: avatarSize, height: avatarSize }} alt="User 1" src={imageUrl} />;
                    }

                    return (
                        <AvatarGroup spacing="small" sx={{ justifyContent: 'flex-start', paddingLeft: 0, alignItems: 'flex-start' }}>
                            {conversation.conversation.conversation_users.slice(0, 2).map((participant) => (
                                <Avatar
                                    sx={{ width: avatarSize, height: avatarSize, marginLeft: -10 }}
                                    alt="User 1"
                                    src={participant.user.user_attributes.profile_picture_url}
                                />
                            ))}
                        </AvatarGroup>
                    );
                };
                const otherUser = user.conversation.conversation_users
                    ? user.conversation.conversation_users.find((usr) => usr.user_id !== user.id)
                    : null;

                return (
                    <Fragment key={user.id}>
                        <ListItemButton
                            onClick={() => {
                                if (admin) {
                                    navigate(`admin/chat/${user.conversation.id}`);
                                } else {
                                    navigate(`/chat/${user.conversation.id}`);
                                }
                            }}
                            sx={{
                                backgroundColor: selectedConversation?.conversation?.id === user.conversation?.id ? 'secondary.light' : null
                            }}
                        >
                            {/* <ListItemAvatar> */}
                            <Grid sx={{ minWidth: 60 }}>
                                <UserProfileImages conversation={user} otherUser={otherUser} />
                            </Grid>
                            {/* </ListItemAvatar> */}
                            <ListItemText
                                primary={
                                    <Grid container alignItems="center" spacing={1} component="span">
                                        <Grid item xs zeroMinWidth component="span">
                                            <Typography
                                                variant="h5"
                                                color="inherit"
                                                component="span"
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    display: 'block'
                                                }}
                                            >
                                                {user.conversation.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item component="span">
                                            <Typography component="span" variant="subtitle2">
                                                {formatDate(user.conversation?.latest_message?.created_at)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                secondary={
                                    <Grid container alignItems="center" spacing={1} component="span">
                                        <Grid item xs zeroMinWidth component="span">
                                            <Typography
                                                variant="caption"
                                                component="span"
                                                sx={{
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    display: 'block'
                                                }}
                                            >
                                                {user.conversation?.latest_message?.message_text}
                                            </Typography>
                                        </Grid>
                                        <Grid item component="span">
                                            {user.conversation?.unread_count !== 0 && (
                                                <Chip
                                                    label={user.conversation?.unread_count}
                                                    component="span"
                                                    color="secondary"
                                                    sx={{
                                                        width: 20,
                                                        height: 20,
                                                        '& .MuiChip-label': {
                                                            px: 0.5
                                                        }
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                }
                            />
                        </ListItemButton>
                        <Divider />
                    </Fragment>
                );
            })}
        </List>
    );
};

ConversationList.propTypes = {
    setConversation: PropTypes.func,
    data: PropTypes.array
};

export default ConversationList;
