/* eslint-disable prettier/prettier */
import React, { } from 'react';

// material-ui
import {
    Grid, Typography
} from '@mui/material';


// project imports

// assets
import PoolsTable from 'views/components/poolsTable.component';
import TeamsTable from 'views/components/teamsTable.component';
import LeaderboardTable from './LeagueLeaderboardTable';

const Pools = ({ eventPools, setSelectedPool, selectedPool, setTab, eventId, eventName, handleGenerateDrawer, leagueLeaderboard, leagueId }) => (
    <Grid item xs={12}>
        {eventPools?.length > 0 ?
            <PoolsTable eventPools={eventPools} setTab={setTab} setSelectedPool={setSelectedPool} />
            : <LeaderboardTable leagueId={leagueId} eventId={eventId} eventName={eventName} showAdmin handleGenerateDrawer={handleGenerateDrawer} />}
    </Grid>
);

export default Pools;